import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export type SeoProps = {
  title?: string
  wordpressTitle?: string
  description?: string
  socialImageRelativeUrl?: string
  lang?: string
  meta?: unknown[]
}

const defaultProps: Partial<SeoProps> = {
  description: '',
  lang: 'de',
  meta: [],
}

function SEO({
  description,
  lang,
  meta,
  title,
  wordpressTitle,
  socialImageRelativeUrl,
}: SeoProps): React.ReactElement {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const socialImageUrl = socialImageRelativeUrl
    ? `${site?.siteMetadata.siteUrl}${socialImageRelativeUrl}`
    : ''
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const basicTitleTemplate = defaultTitle ? `%s | ${defaultTitle}` : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={wordpressTitle || title}
      titleTemplate={wordpressTitle ? null : basicTitleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: socialImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}
SEO.defaultProps = defaultProps

export { SEO }
