import React, { ReactElement } from 'react'
// import { ImageType } from '../../types'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'

type SectionLogoCloudProps = SectionProps & {
  data?: Array<{
    title?: string
    image: ReactElement
  }> | null
}

const defaultProps: Partial<SectionLogoCloudProps> = {
  data: null,
}

const SectionLogoCloud = (props: SectionLogoCloudProps): React.ReactElement => {
  const { data, containerClass } = props
  return (
    <Section containerClass={containerClass}>
      <Container>
        {data && (
          <div className="grid grid-cols-2 sm:grid-cols-10 gap-8 md:max-w-full ">
            {data.map(({ image }, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`logo-cloud-${index}`}
                className="col-span-1 flex justify-center md:col-span-2"
              >
                {image}
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}
SectionLogoCloud.defaultProps = defaultProps

export { SectionLogoCloud }
