import React from 'react'
import { LocalizedLink as Link, useLocalization } from 'gatsby-theme-i18n'
// import { StaticImage } from 'gatsby-plugin-image'
import { Container } from './Container'
import LinkedInIcon from '../icons/components/LinkedIn.svg.react'
import FacebookIcon from '../icons/components/Facebook.svg.react'
import InstagramIcon from '../icons/components/Instagram.svg.react'
import TwitterIcon from '../icons/components/Twitter.svg.react'

export type FooterProps = { siteTitle: string }

const defaultProps: Partial<FooterProps> = {
  siteTitle: '',
}

const Footer = ({ siteTitle }: FooterProps): React.ReactElement => {
  const { locale } = useLocalization()

  return (
    <footer
      className="mt-20 pt-8 pb-10 text-white relative z-10"
      style={{ backgroundColor: '#262626', opacity: '1' }}
    >
      {/* container mx-auto max-w-4xl  */}
      <Container>
        <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-6">
          <div>
            <h3 className="text-xl mb-2">Lösungen</h3>

            <ul className="mt-2 font-light">
              <li>
                <Link to="/loesungen" language={locale}>
                  Services
                </Link>
              </li>
              {/* <li>
                <Link to="/loesungen/neverlate" language={locale}>
                  Neverlate (Register for Beta)
                </Link>
              </li>
              <li>
                <Link to="/loesungen/enitresmart" language={locale}>
                  Entiresmart (Register for Beta)
                </Link>
              </li>
              <li>
                <Link to="preise" language={locale}>
                  Preise
                </Link>
              </li> */}
            </ul>
          </div>

          <div>
            <h3 className="text-xl mb-2">Wie es funktioniert</h3>

            <ul className="mt-2 font-light">
              <li>
                <a
                  href="https://docs.payactive.io/apidoc"
                  target="_blank"
                  title="api integrationen"
                  rel="noreferrer"
                >
                  APIs & Integration
                </a>
              </li>
              <li>
                <Link to="/demo" language={locale}>
                  Termin buchen
                </Link>
              </li>
              {/* <li>
                <Link to="/rechnungszahlung" language={locale}>
                  Rechnungszahlung | E-Rechnung
                </Link>
              </li>
              <li>
                <Link to="/intelligente-lastschriften" language={locale}>
                  Intelligente Lastschriften
                </Link>
              </li>
              <li>
                <Link to="/social-cashback" language={locale}>
                  Social Cashback
                </Link>
              </li> 
              <li>
                <Link to="/api-integrationen" language={locale}>
                  APIs & Integration
                </Link>
              </li>
              */}
            </ul>
          </div>

          <div>
            <h3 className="text-xl mb-2">Unternehmen</h3>
            <ul className="mt-2 font-light">
              <li>
                <Link to="/unternehmen" language={locale}>
                  Über uns
                </Link>
              </li>
              <li>
                <Link to="/nachhaltigkeit" language={locale}>
                  Social Cashback
                </Link>
              </li>
              <li>
                <Link to="/datenschutz" language={locale}>
                  Datenschutzerklärung
                </Link>
              </li>
              <li>
                <Link to="/impressum" language={locale}>
                  Impressum
                </Link>
              </li>
              {/* <li>
                <Link to="/follow-us" language={locale}>
                  Follow Us
                </Link>
              </li> */}
            </ul>
          </div>

          <div>
            <h3 className="text-xl mb-2">Follow us</h3>

            <ul className="mt-2 flex flex-wrap gap-4">
              <li>
                <a
                  href="https://www.linkedin.com/company/payactive/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon className="w-7 h-7" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/payactive"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon className="w-7 h-7" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/payactive/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon className="w-7 h-7" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/payactive.de/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon className="w-7 h-7" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="mt-6 text-sm">
          © {new Date().getFullYear()} {` `}
          {siteTitle}
        </p>
      </Container>
    </footer>
  )
}
Footer.defaultProps = defaultProps

export { Footer }
