// import cx from 'classnames'
import React from 'react'
import ReactMarkdown from 'react-markdown'
// import { ImageType } from '../../types'
import { Carousel } from 'react-responsive-carousel'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import '../../styles/carousel.css'

type SectionTestimonialProps = SectionProps & {
  testimonial?: Array<{
    cite?: string | null
    text?: string | null
    logo?: React.ReactElement | null
    image?: React.ReactElement | null
    url?: string | null
  }> | null
}

const defaultProps: Partial<SectionTestimonialProps> = {
  testimonial: null,
}
const SectionTestimonial = (
  props: SectionTestimonialProps
): React.ReactElement => {
  const { testimonial } = props
  return (
    <Section>
      <Container className="relative">
        <Carousel showArrows showStatus={false} showThumbs={false}>
          {testimonial?.map(({ cite, text, logo, image, url }) => (
            <div
              className="rounded-xl p-5 sm:p-8 text-gray-700 grid gap-5 sm:gap-10 sm:grid-cols-12"
              style={{ backgroundColor: '#93a3c152' }}
            >
              <div className="col-span-1 sm:col-span-3 flex justify-center items-start">
                {image}
              </div>
              <div className="col-span-1 sm:col-span-8 flex flex-col justify-center gap-2">
                {text && (
                  <blockquote
                    style={{ fontFamily: 'Roboto Slab, serif' }}
                    className="sm:text-xl text-white text-left"
                  >
                    <ReactMarkdown>{text}</ReactMarkdown>
                  </blockquote>
                )}
                {cite && (
                  <cite className="block not-italic text-base font-thin text-white mb-2 text-left">
                    <ReactMarkdown>{cite}</ReactMarkdown>
                  </cite>
                )}
                {logo && (
                  <div className="text-left pb-6 md:pb-0">
                    {url ? (
                      <a href={url} title="">
                        {logo}
                      </a>
                    ) : (
                      logo
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </Section>
  )
}
SectionTestimonial.defaultProps = defaultProps

export { SectionTestimonial }
