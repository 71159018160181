import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import { Header } from './Header'
import { Footer } from './Footer'

export type LayoutProps = {
  children: React.ReactNode
}

const defaultProps = {
  children: null,
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata?.title || ``
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'light',
        }}
      />
      <div className={cx('app')}>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className={cx('flex-1 text-white relative')}>{children}</main>
        <Footer siteTitle={siteTitle} />
      </div>
    </>
  )
}
Layout.defaultProps = defaultProps

export { Layout }
