import cx from 'classnames'
import React from 'react'

export type HeadlineVariant =
  | 'underline'
  | 'box'
  | 'box-mix-blend'
  | 'box-centered'
  | 'clean'
  | 'greenUnderline'
  | 'blueUnderline'

export type HeadlineSize = 'xsmall' | 'small' | 'xmedium' | 'medium' | 'large'

type HeadlineProps = React.InputHTMLAttributes<HTMLElement> & {
  tagName?: keyof JSX.IntrinsicElements
  variant?: HeadlineVariant
  fontSize?: HeadlineSize
  inverted?: boolean
}

const defaultProps: HeadlineProps = {
  tagName: 'h2',
  variant: 'box',
  fontSize: 'large',
  inverted: false,
}

const Headline = (props: HeadlineProps): React.ReactElement => {
  const { tagName, children, className, variant, fontSize, inverted } = props
  const Tag = tagName as keyof JSX.IntrinsicElements

  let styles
  const checkStyles = () => {
    if (variant === 'underline') {
      styles = {
        backgroundPosition: '0% 100%',
        backgroundSize: '100% 0.5em',
        backgroundImage: inverted
          ? 'linear-gradient(rgba(94, 140, 179, 0.22), rgba(94, 140, 179, 0.22))'
          : 'linear-gradient(rgba(255, 255, 255, 0.22), rgba(255, 255, 255, 0.22))',
        // transition: 'background-size .3s',
      }
    } else if (variant === 'greenUnderline') {
      styles = {
        color: '#000000',
        backgroundPosition: '0% 100%',
        backgroundSize: '100% 0.5em',
        padding: '0 0.2em',
        '-webkit-box-decoration-break': 'clone',
        'box-decoration-break': 'clone',
        boxDecorationBreak: 'clone',
        backgroundImage: inverted
          ? 'linear-gradient(rgba(94, 140, 179, 0.22), rgba(94, 140, 179, 0.22))'
          : 'linear-gradient(rgb(0 226 209), rgb(0 226 209))',
      }
    } else if (variant === 'blueUnderline') {
      styles = {
        color: '#33444c',
        backgroundPosition: '0% 100%',
        backgroundSize: '100% 0.5em',
        padding: '0 0.2em',
        webkitBoxDecorationBreak: 'clone',
        boxDecorationBreak: 'clone',
        backgroundImage: inverted
          ? 'linear-gradient(rgba(94, 140, 179, 0.22), rgba(94, 140, 179, 0.22))'
          : 'linear-gradient(rgb(200 211 225), rgb(200 211 225))',
      }
    } else {
      styles = null
    }
  }

  checkStyles()

  return (
    <Tag
      className={cx('', {
        'text-4xl lg:text-5xl xl:text-6xl': fontSize === 'large',
        'text-3xl lg:text-4xl xl:text-5xl': fontSize === 'medium',
        'text-3xl': fontSize === 'xmedium',
        'text-2xl': fontSize === 'small',
        'text-1xl ml-3': fontSize === 'xsmall',
        // line-height
        'lg:leading-0':
          variant === 'box' ||
          variant === 'box-mix-blend' ||
          variant === 'box-centered',
        'lg:leading-snug xl:leading-snug':
          variant === 'underline' ||
          variant === 'clean' ||
          variant === 'greenUnderline' ||
          variant === 'blueUnderline',
        'font-serif':
          variant === 'underline' ||
          variant === 'greenUnderline' ||
          variant === 'blueUnderline',
        'font-serif opacity-70': variant === 'clean',
        'text-gray-charcoal': inverted,
        'text-center': variant === 'blueUnderline',
        [className as string]: className,
      })}
    >
      <span
        className={cx({
          'inline-block': variant === 'box' || variant === 'box-mix-blend',
          'px-3 lg:px-4 py-2 lg:py-3 bg-white text-titlesBlue specialWeights':
            variant === 'box' || variant === 'box-centered',
          'inline-block px-2 lg:px-3 py-1 lg:py-2 bg-white text-black mix-blend-screen specialWeights':
            variant === 'box-mix-blend',
          'block text-center': variant === 'box-centered',
          'bg-no-repeat':
            variant === 'underline' ||
            variant === 'greenUnderline' ||
            variant === 'blueUnderline',
        })}
        style={styles}
      >
        {children}
      </span>
    </Tag>
  )
}
Headline.defaultProps = defaultProps

export { Headline }
