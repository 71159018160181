/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react'
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
// import { StaticImage } from 'gatsby-plugin-image'
import { Popover } from '@headlessui/react'
import { Container } from './Container'
import CollapsedMenu from './CollapsedMenu'
import menu from './menu'
import PayactiveIcon from '../icons/components/Payactive.svg.react'

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const tailwindConfig = require('../../../tailwind.config')

export type HeaderProps = {
  siteTitle: string
}

const defaultProps: Partial<HeaderProps> = {
  siteTitle: '',
}

const Header = (/* { siteTitle }: HeaderProps */): React.ReactElement => (
  <header id="header" className="text-white relative z-10 bg-transparent">
    <Popover className="w-full">
      {() => (
        <>
          <Container>
            <div className="py-5 sm:py-10 flex items-center justify-between">
              <Link to="/" language="de" className="flex items-center">
                <PayactiveIcon
                  // style={{ width: 250 }}
                  className="filter brightness-0 invert w-44 sm:w-60"
                />
              </Link>

              <nav className="hidden lg:block">
                <ul className="flex space-x-7  items-center">
                  {menu
                    .filter((i) => !i.hidden)
                    .map(({ label, url, isExternal }) => (
                      <li key={url}>
                        {isExternal ? (
                          <a
                            href={url}
                            target="_blank"
                            title={label}
                            rel="noreferrer"
                          >
                            {label}
                          </a>
                        ) : (
                          <Link to={url} language="de" className="px-0 py-3">
                            {label}
                          </Link>
                        )}
                      </li>
                    ))}
                  <li className="hidden xl:block">
                    <Link
                      style={{ color: '#4EAFC5' }}
                      to="/demo"
                      language="de"
                      className="bg-white px-6 py-3 rounded "
                    >
                      Demo anfordern
                    </Link>
                  </li>
                  <li className="block xl:hidden">
                    <Link
                      style={{ color: '#4EAFC5' }}
                      to="/demo"
                      language="de"
                      className="bg-white px-6 py-3 rounded "
                    >
                      Demo
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="lg:hidden">
                <CollapsedMenu />
              </div>
            </div>
          </Container>
        </>
      )}
    </Popover>
  </header>
)
Header.defaultProps = defaultProps

export { Header }
