import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { Button } from '../Button'
import LetterIcon from '../icons/components/Letter.svg.react'
import EmailIcon from '../icons/components/Email.svg.react'

export const privacyText = `Ich willige ein, dass meine E-Mail-Adresse zum Zweck des Abonnements und regelmäßigen Versands des Newsletters zu Branchen Themen verarbeitet wird und dass auf meinem Computer mit Hilfe eines Pixels und ähnlicher Technologien mein Klick- und Öffnungsverhalten in Bezug auf das jeweilige Newsletter sowie Angaben über genutzte Geräte (wie PC, Handy oder Tablets) und die Region zum Zweck der Erfolgsmessung durch [payactive GmbH](https://www.payactive.eu/de/impressum/) und [Hubspot](https://legal.hubspot.com/de/impressum) in den USA, wie in der [Datenschutzerklärung](https://www.payactive.eu/de/datenschutz/) näher beschrieben, verarbeitet werden. Die USA werden vom Europäischen Gerichtshof als ein Land mit einem nach EU-Standards unzureichendem Datenschutzniveau eingeschätzt. Es besteht insbesondere das Risiko, dass Ihre Daten durch US-Behörden zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden.

**Diese Einwilligung ist jederzeit mit Wirkung für die Zukunft widerruflich. Die Rechtmäßigkeit der Verarbeitung Ihrer Daten vor dem Widerruf wird durch diesen nicht berührt. Einen Link zur Kündigung finden Sie am Ende eines jeden Newsletters.**
`

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse an.')
    .required('Bitte geben Sie Ihre E-Mail-Adresse an.'),
  consent: Yup.bool().oneOf([true], 'Das ist ein Pflichtfeld.'),
})

export type NewsletterFormType = {
  email: string
  consent: boolean
}

const initialValues: NewsletterFormType = {
  email: '',
  consent: false,
}

type Props = {
  handleSubmit: (
    values: NewsletterFormType,
    actions: { setSubmitting: (value: boolean) => void }
  ) => void
}

const NewsletterForm = ({ handleSubmit }: Props): React.ReactElement => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        handleSubmit(values, actions)
      }}
    >
      {({ isValid, isSubmitting, errors }) => {
        return (
          <Form>
            <div className="mb-8 flex items-start lg:w-1/2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              {/* <label htmlFor="email">Email</label> */}
              <EmailIcon className="mt-2 mr-3 w-7 h-7 text-white" />

              <div className="flex-1">
                <Field
                  id="email"
                  name="email"
                  placeholder="Ihre E-Mail-Adresse"
                  type="email"
                  className="bg-transparent text-white w-full block placeholder-white placeholder-opacity-70 border-b border-white border-opacity-70 focus:border-opacity-100 py-2 outline-none"
                />

                {errors.email && <p className="text-sm mt-1">{errors.email}</p>}
              </div>
            </div>

            <div className="mb-8 lg:w-1/2">
              <label
                htmlFor="consent"
                className="flex items-start cursor-pointer"
              >
                <Field
                  id="consent"
                  name="consent"
                  // placeholder="Ihre E-Mail-Adresse"
                  type="checkbox"
                  className="mt-1.5 mr-4 cursor-pointer"
                />

                <div className="flex-1">
                  Ich stimme zu, Benachrichtigungen von payactive.eu zu
                  erhalten.
                  {errors.consent && (
                    <p className="text-sm mt-1">{errors.consent}</p>
                  )}
                </div>
              </label>
            </div>

            {/* <ReactMarkdown
              className="formattedHTML text-white text-xs opacity-80 font-light mb-4"
              linkTarget="_blank"
            >
              {privacyText}
            </ReactMarkdown> */}

            <Button
              icon={<LetterIcon className="w-8 h-8" />}
              type="submit"
              variant="secondary"
              disabled={!isValid || isSubmitting}
            >
              Abonnieren
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export { NewsletterForm }
