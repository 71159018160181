import React from 'react'
import ReactMarkdown from 'react-markdown'
import { navigate } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import cx from 'classnames'
import { Section, SectionProps } from './Section'
import { Container } from '../Layout'
import { Button } from '../Button'
import { Headline } from '../Typography'
import { ButtonType } from '../../types'
import { removeSlashes } from '../../lib'
import '@fontsource/architects-daughter'
import arrow from '../../images/arrow-handdrawn.svg'
import arrowUp from '../../images/arrow-handdrawn-up.svg'

export type SectionBannerProps = SectionProps & {
  title?: string
  text?: string
  subtext?: string
  image?: React.ReactElement
  actionLinks?: Array<{
    url: string
    label: string
    variant?: ButtonType
  }> | null
  singlePost?: boolean
}

const SectionBanner = (props: SectionBannerProps): React.ReactElement => {
  const { title, text, subtext, image, actionLinks, singlePost } = props
  const { locale } = useLocalization()

  return (
    <Section>
      <div className="overflow-hidden md:py-28" id="branches-after">
        <Container>
          <div className="relative bg-dirtyWhite rounded-xl p-5 md:p-10 md:max-w-5xl">
            <div className="grid md:grid-cols-12 gap-10 md:gap-0">
              <div
                className={cx({
                  'md:col-span-8': image,
                  'md:col-span-12': !image,
                })}
              >
                {title && (
                  <Headline
                    variant="underline"
                    inverted
                    className="mb-8"
                    fontSize="xmedium"
                  >
                    {title}
                  </Headline>
                )}

                {text && (
                  <ReactMarkdown
                    className={cx({
                      'text-gray-charcoal font-thin text-xl': !singlePost,
                      'text-gray-charcoal font-thin text-xl w-5/6': singlePost,
                    })}
                  >
                    {text}
                  </ReactMarkdown>
                )}

                <div className="flex flex-col sm:flex-row flex-wrap items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-10">
                  {actionLinks &&
                    !!actionLinks.length &&
                    actionLinks.map(({ url, label, variant }) => (
                      <>
                        {variant === 'link' ? (
                          <a
                            className="bg-transparent underline text-gray-charcoal inline-block px-8 md:px-3 lg:px-8 py-3"
                            href={url}
                            target="_top"
                          >
                            {label}
                          </a>
                        ) : (
                          <Button
                            onClick={() =>
                              navigate(`/${locale}/${removeSlashes(url)}`)
                            }
                            className=""
                            variant={variant || 'secondary'}
                          >
                            {label}
                          </Button>
                        )}
                      </>
                    ))}
                </div>
              </div>

              {image && (
                // md:scale-125
                <div className="mx-auto md:col-span-2 md:absolute md:top-1/2 md:-translate-y-1/2 md:transform  md:-right-48 lg:-right-40">
                  {image}
                </div>
              )}
            </div>
          </div>
          <div className="hidden md:flex mt-20 lg:pl-28 xl:pl-56 relative">
            <h1
              className="md:text-2xl md:w-full"
              style={{ fontFamily: 'Architects Daughter' }}
            >
              {subtext}
            </h1>
            {subtext && (
              <img
                className="md:absolute md:right-28 lg:right-64 -top-16"
                src={arrow}
                alt="arrow"
                style={{
                  transform: 'rotate(251deg)',
                }}
              />
            )}
          </div>
          <div className="flex flex-col-reverse md:hidden mt-6 lg:pl-28 xl:pl-56 relative">
            <h1
              className="text-md text-center mt-4 w-3/5 self-center"
              style={{ fontFamily: 'Architects Daughter' }}
            >
              {subtext}
            </h1>
            {subtext && (
              <img
                src={arrowUp}
                alt="arrow"
                style={{
                  transform: 'rotate(355deg)',
                  width: 'fitContent',
                  alignSelf: 'center',
                }}
              />
            )}
          </div>
        </Container>
      </div>
    </Section>
  )
}

export { SectionBanner }
