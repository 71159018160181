import React, { SVGProps } from 'react'

const LetterIcon = ({
  className,
}: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="131.772"
    height="68.113"
    viewBox="0 0 131.772 68.113"
    className={className}
  >
    <path
      id="Pfad_3"
      data-name="Pfad 3"
      d="M122.709,11.33H45.285A10.5,10.5,0,0,0,34.8,21.815V32.674H4.341a2.918,2.918,0,0,0,0,5.837H34.8v2.9H29.762a2.918,2.918,0,1,0,0,5.837H34.8v5.028H19.864a2.918,2.918,0,1,0,0,5.837H34.8V68.954A10.5,10.5,0,0,0,45.285,79.442h77.425a10.5,10.5,0,0,0,10.485-10.488V21.815A10.5,10.5,0,0,0,122.709,11.33Zm4.649,56.045v1.579a4.656,4.656,0,0,1-4.649,4.652H45.285a4.657,4.657,0,0,1-4.652-4.652V21.815a4.663,4.663,0,0,1,.178-1.2L82.563,44.228a2.926,2.926,0,0,0,2.872,0l41.749-23.609a4.6,4.6,0,0,1,.175,1.2v45.56ZM46.575,17.167h74.854L84,38.333Z"
      transform="translate(-1.423 -11.33)"
    />
  </svg>
)

export default LetterIcon
