import React from 'react'
import { navigate } from 'gatsby'
// import ReactMarkdown from 'react-markdown'
import { useLocalization } from 'gatsby-theme-i18n'
import cx from 'classnames'
import Typewriter from 'typewriter-effect'
import { Container } from '../Layout'
import { Button } from '../Button'
import { Headline, HeadlineSize, Lead } from '../Typography'
import { Section, SectionProps } from './Section'
import { ButtonType, ImageType } from '../../types'
import { removeSlashes } from '../../lib'

type SectionHeroProps = SectionProps & {
  typewritter?: boolean
  title?: string | string[]
  titleTag?: keyof JSX.IntrinsicElements
  subtitle?: string | null
  subtitleTag?: string | null
  text?: string | null
  textTag?: string | null
  textSize?: string
  actionLinks?: Array<{
    url: string
    label: string
    variant?: ButtonType
    type?: string
  }> | null
  image?: React.ReactElement | ImageType | null
  framelessImage?: boolean
  from?: string
}

const defaultProps: Partial<SectionHeroProps> = {
  title: '',
  typewritter: false,
  titleTag: 'h2',
  subtitle: null,
  subtitleTag: 'p',
  text: null,
  textTag: 'p',
  textSize: 'large',
  actionLinks: null,
  image: null,
  framelessImage: false,
  from: '',
}

const SectionHero = (props: SectionHeroProps): React.ReactElement => {
  const {
    containerClass,
    text,
    typewritter,
    title,
    titleTag,
    subtitle,
    subtitleTag,
    textTag,
    textSize,
    actionLinks,
    image,
    framelessImage,
    from,
  } = props
  const { locale } = useLocalization()
  const HeroTextTag = textTag as keyof JSX.IntrinsicElements

  return (
    <Section
      containerClass={
        containerClass || from === 'home' ? 'pb-0' : 'sm:pb-12 lg:pb-12'
      }
    >
      <div id={`${from === 'home' && 'first-page-home'}`}>
        <div
          // lg:py-48
          className={cx('overflow-hidden pt-8 sm:pt-12 lg:relative ', {
            'text-center md:text-left': from === 'home' || from === 'loesungen',
            // 'lg:py-32': !framelessImage,
            // 'lg:py-48': framelessImage,
          })}
        >
          <Container>
            <div
              className={cx({
                'grid lg:grid-cols-2 gap-10 lg:gap-18': from === 'checkout',
                'grid lg:grid-cols-2 gap-10 lg:gap-24': from !== 'checkout',
                'lg:max-w-4xl': !image,
              })}
            >
              {/* md:w-1/2 */}
              <div
                className={cx({
                  'col-span-2': !image,
                  'col-span-1': image,
                })}
              >
                {/* if typewritter the order change and we remove padding */}
                {typewritter && (
                  <>
                    <Lead tagName={subtitleTag as keyof JSX.IntrinsicElements}>
                      {typewritter && subtitle}
                    </Lead>
                    <Headline
                      tagName={titleTag}
                      fontSize={textSize as HeadlineSize}
                    >
                      <Typewriter
                        options={{
                          strings: title,
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </Headline>
                  </>
                )}

                {/* if !typewritter  */}

                {!typewritter && (
                  <>
                    <Lead tagName={subtitleTag as keyof JSX.IntrinsicElements}>
                      {typewritter && subtitle}
                    </Lead>
                    <Headline
                      tagName={titleTag}
                      className={cx('', {
                        'mb-8 lg:pt-32': textSize === 'large',
                        'mb-8 lg:pt-16': textSize === 'medium',
                      })}
                      fontSize={textSize as HeadlineSize}
                    >
                      {title}
                    </Headline>
                    <Lead tagName={subtitleTag as keyof JSX.IntrinsicElements}>
                      {!typewritter && subtitle}
                    </Lead>
                  </>
                )}

                {text && (
                  <HeroTextTag
                    className={
                      typewritter
                        ? 'mt-10 text-xl font-thin'
                        : 'text-xl font-thin'
                    }
                  >
                    {text}
                  </HeroTextTag>
                )}

                <div className="space-y-4 sm:space-y-0 sm:space-x-4 mt-10">
                  {actionLinks &&
                    !!actionLinks.length &&
                    actionLinks.map(({ url, label, variant, type }) => (
                      <>
                        {type === 'email' ? (
                          <a
                            className="bg-transparent underline text-white"
                            href={url}
                            target="_top"
                          >
                            {label}
                          </a>
                        ) : (
                          <Button
                            onClick={() =>
                              type === 'scroll'
                                ? document
                                    .getElementById('services-section')
                                    ?.scrollIntoView({
                                      behavior: 'smooth',
                                    })
                                : navigate(`/${locale}/${removeSlashes(url)}`)
                            }
                            className=""
                            variant={variant || 'secondary'}
                          >
                            {label}
                          </Button>
                        )}
                      </>
                    ))}
                </div>
              </div>

              <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                {image && framelessImage ? (
                  <div className="py-12 sm:relative sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <div className="relative pl-4 -mr-40 sm:-mr-48 lg:-mr-20 2xl:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full">
                      {image}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center">
                    {React.isValidElement(image) ? (
                      image
                    ) : (
                      <img src={image?.uri} alt={image?.alt || ''} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Section>
  )
}
SectionHero.defaultProps = defaultProps

export { SectionHero }
