import React from 'react'
import cx from 'classnames'

export const FEATURED_SLUG = 'featured'

export type PostPreviewProps = {
  title?: string
  subtitle?: React.ReactElement | string
  featuredImage?: {
    altText: string
    mediaItemUrl: string
  } | null
  labels?: Array<{
    id: string
    slug: string
    name: string
  }>
  isHighlight?: boolean
  sectionPostsView?: boolean
  author?: {
    firstName: string
    lastName: string
  }
  date?: string | number
}

const defaultProps = {
  title: '',
  subtitle: '',
  featuredImage: null,
  labels: [],
  isHighlight: false,
  sectionPostsView: false,
  author: {},
  date: '',
}

const PostPreview = ({
  title,
  subtitle,
  labels,
  featuredImage,
  isHighlight,
  sectionPostsView,
  author,
  date,
}: PostPreviewProps): React.ReactElement => {
  const isFeatured = labels?.some(({ slug }) => slug === FEATURED_SLUG)

  const oldDateFormat = new Date(date)
  const newDateFormat = oldDateFormat?.toLocaleDateString()

  return (
    <div className="relative rounded-lg flex flex-col justify-between h-full p-3">
      {labels && !!labels.length && !sectionPostsView && (
        <div className="self-end relative z-10 flex gap-3">
          {labels
            .filter((l) => l.slug !== FEATURED_SLUG)
            .map((label) => (
              // bg-opacity-80

              <span
                className="bg-dirtyWhite text-gray-600 text-xs  rounded px-2 py-1"
                key={label.id}
              >
                {label.name}
              </span>
            ))}
        </div>
      )}

      <div
        className={cx(
          'inline-flex flex-col bg-dirtyWhite bg-opacity-80 p-3 rounded relative z-10',
          {
            'lg:w-2/3': isHighlight,
          },
          {
            'lg:bg-white': sectionPostsView,
          }
        )}
      >
        {sectionPostsView && author && date && (
          <h5 className="font-thin text-gray-600 text-sm mb-1">
            {newDateFormat} | {`${author?.firstName} ${author?.lastName}`}
          </h5>
        )}
        <h3
          className={cx(
            'font-light line-clamp-2 text-gray-700',
            {
              'lg:text-3xl lg:line-clamp-3': isHighlight,
            },
            {
              'font-medium': sectionPostsView,
            }
          )}
        >
          {isFeatured && (
            <span
              className={cx(
                'font-normal capitalize',
                isHighlight ? 'mr-2' : 'mr-1'
              )}
            >
              {labels?.find((l) => l.slug === FEATURED_SLUG)?.name}
            </span>
          )}
          {title}
        </h3>
        {sectionPostsView && (
          <div className="border-b-8 border-solid border-black w-20 mt-2" />
        )}

        {subtitle && isHighlight && (
          <p className="font-thin text-sm text-gray-600 hidden lg:block ">
            {subtitle}
          </p>
        )}
      </div>
      {!sectionPostsView && (
        <img
          className="absolute z-0 object-cover rounded-lg h-full w-full top-0 left-0 bg-white"
          src={featuredImage?.mediaItemUrl}
          alt={featuredImage?.altText}
        />
      )}
    </div>
  )
}
PostPreview.defaultProps = defaultProps

export { PostPreview }
