export type MenuItemType = {
  label: string
  url: string
  isExternal?: boolean
  hidden?: boolean
}

const menuItems: Array<MenuItemType> = [
  {
    label: 'Lösungen',
    url: '/loesungen',
    // hidden: true,
  },
  {
    label: 'Unternehmen',
    url: '/unternehmen',
  },
  {
    label: 'Nachhaltigkeit',
    url: '/nachhaltigkeit',
  },
  {
    label: 'Entwickler',
    url: 'https://docs.payactive.io/apidoc',
    isExternal: true,
  },
  {
    label: 'Blog',
    url: '/blog',
  },
  {
    label: 'Kontakt',
    url: '/kontakt',
  },
]

export default menuItems
