import React from 'react'
// import cx from 'classnames'
import { Link } from 'gatsby'
import cx from 'classnames'
import FilledCaretRight from '../icons/components/FilledCaretRight.svg.react'
import { Section, SectionProps } from './Section'
import { Container } from '../Layout'

export type SectionActionsReelProps = SectionProps & {
  data: { title: string; subtitle?: string; primary?: boolean }[]
}

const SectionActionsReel = (
  props: SectionActionsReelProps
): React.ReactElement => {
  const { data } = props

  return (
    <Section>
      <Container>
        <div className="flex space-x-7 items-center overflow-x-scroll">
          {data.map(({ title, subtitle, primary }) => (
            <Link
              key={title}
              to="/"
              className={cx(
                'rounded py-7 px-7 flex items-center transform w-50',
                {
                  'bg-white text-brand h-30': !primary,
                  'bg-brand text-white h-40': primary,
                }
              )}
            >
              {primary ? (
                <>
                  <p className="text-2xl">{title}</p>
                  <FilledCaretRight />
                </>
              ) : (
                <p>
                  {subtitle}
                  <span className="block text-2xl">{title}</span>
                </p>
              )}
            </Link>
          ))}
        </div>
      </Container>
    </Section>
  )
}

export { SectionActionsReel }
