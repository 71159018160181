import cx from 'classnames'
import React from 'react'

export type ContainerProps = React.InputHTMLAttributes<HTMLElement>

const Container = ({
  children,
  className,
}: ContainerProps): React.ReactElement => {
  return (
    <div
      // md:px-0
      className={cx(
        'sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto px-3',
        {
          [className as string]: !!className,
        }
      )}
    >
      {children}
    </div>
  )
}

export { Container }
