import cx from 'classnames'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Container } from '../Layout'
import { Headline, HeadlineVariant, Lead } from '../Typography'
import { Section, SectionProps } from './Section'

type SectionTextProps = SectionProps & {
  title: string
  from?: string
  containerId: string | null
  centered?: boolean
  subtitleFirst?: boolean
  subtitle?: string | null
  text?: string | null
  headlineVariant?: HeadlineVariant
  actionLink?: { url: string; label: string } | null
  /** Deprecated */
  headlineHighlighted?: boolean
}

const defaultProps: Partial<SectionTextProps> = {
  centered: false,
  subtitleFirst: false,
  headlineHighlighted: true,
  subtitle: null,
  text: null,
  headlineVariant: 'underline',
  actionLink: null,
  from: '',
}

const SectionText = (props: SectionTextProps): React.ReactElement => {
  const {
    title,
    from,
    centered,
    subtitle,
    subtitleFirst,
    text,
    headlineHighlighted,
    containerId,
    containerClass,
    headlineVariant,
    actionLink,
  } = props
  return (
    <Section containerId={containerId} containerClass={containerClass}>
      <Container>
        <div
          // lg:max-w-4xl
          className={cx('break-words sm:break-normal', {
            // 'text-center': titleCentered,
          })}
        >
          {subtitleFirst && subtitle && (
            <Lead
              className={cx('mb-0', {
                'text-center': centered,
              })}
            >
              {subtitle}
            </Lead>
          )}

          {headlineHighlighted ? (
            <Headline
              fontSize={from === 'home-subtitle' ? 'small' : 'large'}
              variant={headlineVariant}
              className={cx({ 'text-center': centered })}
            >
              {title}
            </Headline>
          ) : (
            <h2 className="text-5xl">{title}</h2>
          )}
          {actionLink && (
            <a
              href={actionLink.url}
              title={actionLink.label}
              className={cx(
                'block mt-5 font-serif text-4xl lg:text-5xl xl:text-6xl text-brand-light'
              )}
            >
              {actionLink.label}
            </a>
          )}

          {(subtitle || text) && (
            <div className="mt-4">
              {!subtitleFirst && subtitle && (
                <Lead
                  className={cx({
                    'text-center': centered,
                  })}
                >
                  {subtitle}
                </Lead>
              )}

              {text && (
                <ReactMarkdown
                  className={cx('text-xl font-thin', {
                    'text-center mx-auto lg:max-w-3x': centered,
                    'text-gray-charcoal': from === 'loesungen',
                  })}
                >
                  {text}
                </ReactMarkdown>
              )}
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}
SectionText.defaultProps = defaultProps

export { SectionText }
