import React, { SVGProps } from 'react'

const EmailIcon = ({
  className,
}: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    height="60.004"
    viewBox="0 0 60.012 60.004"
    width="60.012"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      d="m50 20a30 30 0 0 0 -30 30 27.88 27.88 0 0 0 3.35 13.74l-3 8.21a6 6 0 0 0 7.69 7.69l8.21-3a27.88 27.88 0 0 0 13.75 3.36 29.83 29.83 0 0 0 16.74-5.12l-3.74-4.73a23.88 23.88 0 0 1 -13 3.85 21.08 21.08 0 0 1 -13-4l-11 4 4-11a21.08 21.08 0 0 1 -4-13 24 24 0 1 1 48 0v4a5 5 0 0 1 -10 0v-4a14 14 0 1 0 -4.21 10 11 11 0 0 0 20.21-6v-5a30 30 0 0 0 -30-29zm0 38a8 8 0 1 1 8-8 8 8 0 0 1 -8 8z"
      transform="translate(-19.988 -20)"
    />
  </svg>
)

export default EmailIcon
