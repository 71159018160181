import React, { ReactNode } from 'react'
import cx from 'classnames'

export type SectionProps = {
  children?: ReactNode
  containerId?: string | undefined
  containerClass?: string | null
  showSmaller?: boolean
  stickySidePosts?: boolean
}

const defaultProps: Partial<SectionProps> = {
  containerId: '',
  containerClass: null,
  showSmaller: false,
  stickySidePosts: false,
}

const Section = (props: SectionProps): React.ReactElement => {
  const {
    children,
    containerId,
    containerClass,
    showSmaller,
    stickySidePosts,
  } = props
  return (
    <div
      id={containerId}
      className={cx(
        containerClass || 'py-14',
        {
          'max-w-4xl mx-auto py-14': showSmaller,
        },
        {
          'sticky top-32': stickySidePosts,
        }
      )}
    >
      {children}
    </div>
  )
}
Section.defaultProps = defaultProps

export { Section }
