import React from 'react'
import { TeamMemberType } from '../../types'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'

type SectionTextProps = SectionProps & {
  data?: Array<TeamMemberType> | null
}

const defaultProps: Partial<SectionTextProps> = {
  data: null,
}

const SectionTeam = (props: SectionTextProps): React.ReactElement => {
  const { data, containerId, containerClass } = props
  return (
    <Section containerId={containerId} containerClass={containerClass}>
      <Container>
        {data && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
            {data.map(({ name, position, image }) => (
              <div
                className="bg-dirtyWhite bg-opacity-10 rounded  text-center p-4"
                style={{ marginTop: 110 }}
              >
                {image && (
                  <div
                    className="flex justify-center mb-4"
                    style={{ marginTop: -110 }}
                  >
                    {image}
                  </div>
                )}

                <p className="">{name}</p>
                <p className="font-light">{position}</p>
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}
SectionTeam.defaultProps = defaultProps

export { SectionTeam }
