import React, { createRef, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import cx from 'classnames'
import { useLocalization } from 'gatsby-theme-i18n'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'
import { Button } from '../Button'
import { removeSlashes } from '../../lib'

type SectionProductsProps = SectionProps & {
  data?: Array<{
    label: string | null
    title?: string | null
    text?: string | null
    image?: React.ReactElement | null
    actionLinks?: Array<{
      url: string
      label: string
    }>
  }> | null
}

const defaultProps: Partial<SectionProductsProps> = {
  data: null,
}

export const SectionTabs = (
  props: SectionProductsProps
): React.ReactElement | null => {
  const { locale } = useLocalization()
  const { containerClass, data } = props
  const [active, setActive] = useState<number>(0)

  if (!data) return null

  const tabsRef = useRef(data.map(() => createRef()))
  const { offsetLeft } = (tabsRef.current[active]?.current as any) || {}

  return (
    <Section containerClass={containerClass}>
      <Container>
        {data && !!data.length && (
          <>
            <div className="relative inline-block">
              <ul className="md:inline-flex gap-8 md:-mx-2">
                {data.map(({ label }, i) => (
                  <li
                    className={cx('text-xl flex-1')}
                    key={label}
                    ref={tabsRef.current[i] as React.LegacyRef<HTMLLIElement>}
                  >
                    <button
                      className={cx('p-2 focus:outline-none font-light')}
                      style={{
                        textShadow:
                          active === i
                            ? `0 0 .65px #fff, 0 0 .65px #fff`
                            : 'none',
                      }}
                      onClick={() => {
                        setActive(i)
                      }}
                      type="button"
                    >
                      {label}
                    </button>
                  </li>
                ))}
              </ul>
              <div
                className="invisible md:visible w-14 mx-2 h-0.5 bg-white absolute left-0 transition-all"
                style={{
                  left: `${offsetLeft}px`,
                }}
              />
            </div>

            {data && data[active] && (
              <div className="grid gap-5 md:gap-10 md:grid-cols-5 mt-6">
                <div className="md:col-span-3">
                  <div className="bg-opacity-10 bg-dirtyWhite rounded-xl p-9">
                    <h3 className="text-3xl mb-6  ">{data[active].title}</h3>
                    <p className="font-light text-xl">{data[active].text}</p>

                    <div className="-mx-5">
                      {data[active].actionLinks &&
                        data[active].actionLinks?.map(({ url, label }) => (
                          <Button
                            className="mt-8"
                            variant="link"
                            onClick={() =>
                              navigate(`/${locale}/${removeSlashes(url)}`)
                            }
                          >
                            {label}
                          </Button>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="md:col-span-2 flex justify-center">
                  {data[active].image || null}
                </div>
              </div>
            )}
          </>
        )}
      </Container>
    </Section>
  )
}
SectionTabs.defaultProps = defaultProps

export default SectionTabs
