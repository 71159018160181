import React, { Fragment } from 'react'
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { MenuIcon } from '@heroicons/react/outline'
import { Menu, Transition } from '@headlessui/react'
import menu from './menu'

const CollapsedMenu = (): React.ReactElement => {
  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none">
              Menü
              <MenuIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {menu
                  .filter((i) => !i.hidden)
                  .map(({ label, url, isExternal }) => (
                    <Menu.Item>
                      {isExternal ? (
                        <a
                          href={url}
                          title={label}
                          className="px-4 py-2 text-gray-600 block"
                        >
                          {label}
                        </a>
                      ) : (
                        <Link
                          to={url}
                          language="de"
                          className="px-4 py-2 text-gray-600 block"
                        >
                          {label}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}

                <Menu.Item>
                  <div>
                    <Link
                      to="/demo"
                      language="de"
                      className="px-3 py-2 mx-2 my-1 rounded bg-gray-600 text-white block"
                    >
                      Demo anfordern
                    </Link>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default CollapsedMenu
