import React from 'react'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'
import { Headline } from '../Typography'

export type Column = {
  image?: React.ReactElement | null
  title?: string
  text?: string
}

type SectionColumnProps = SectionProps & {
  data?: Array<Column> | null
  gap: string
  from?: string
  variant: string
}

const defaultProps: Partial<SectionColumnProps> = {
  data: [],
  from: '',
}

const SectionColumns = (props: SectionColumnProps): React.ReactElement => {
  const { data, gap, containerClass, from, variant } = props

  let containerStyle = {}
  let boxStyle = {}
  if (variant === 'white') {
    containerStyle = {}
    boxStyle = {}
  } else if (variant === 'lightGrey') {
    containerStyle = {
      borderRadius: '10px',
    }
    boxStyle = {
      background: '#E6E7ED 0% 0% no-repeat padding-box',
      borderRadius: '10px',
    }
  }
  return (
    <Section containerClass={containerClass}>
      <Container>
        {data && (
          <div
            className={cx(
              `grid grid-cols-1 md:grid-cols-${data.length}`,
              gap || 'gap-10'
            )}
            style={containerStyle}
          >
            {data?.map(({ image, title, text }) => (
              <div key={title} style={boxStyle}>
                {image}

                {title && (
                  <Headline
                    variant={
                      variant === 'lightGrey' ? 'blueUnderline' : 'underline'
                    }
                    fontSize={variant === 'white' ? 'xmedium' : 'small'}
                    className={cx(
                      variant === 'lightGrey'
                        ? 'text-gray-charcoal text-center mb-5'
                        : '',
                      variant === 'white'
                        ? 'text-gray-charcoal text-center font-bold'
                        : ''
                    )}
                  >
                    {title}
                  </Headline>
                )}

                {text && from === 'home' && (
                  <p
                    style={{ color: '#37404A' }}
                    className="font-thin leading-7"
                  >
                    {text}
                  </p>
                )}
                {text && from !== 'home' && (
                  <ReactMarkdown
                    className={cx(
                      variant === 'lightGrey'
                        ? 'text-gray-charcoal font-thin'
                        : '',
                      variant === 'white'
                        ? 'text-gray-charcoal text-center'
                        : ''
                    )}
                  >
                    {text}
                  </ReactMarkdown>
                )}
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}
SectionColumns.defaultProps = defaultProps

export { SectionColumns }
