import React from 'react'
import { ImageType } from '../../types'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'

type SectionProductsProps = SectionProps & {
  data: Array<{
    title: string | React.ReactNode
    subtitle: string | React.ReactNode
    image: ImageType
  }>
}

export const SectionProducts = (
  props: SectionProductsProps
): React.ReactElement => {
  const { data, containerClass } = props

  return (
    <Section containerClass={containerClass}>
      <Container>
        {data && !!data.length && (
          <div className="md:flex gap-8">
            {data.map(({ title, subtitle, image }) => (
              <div className="md:flex-1">
                <p className="mb-1">{subtitle}</p>
                <h3 className="text-3xl mb-3">{title}</h3>
                <img src={image.uri} alt={image.alt} />
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}

export default SectionProducts
