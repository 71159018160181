import React, { SVGProps } from 'react'

const FilledCaretRight = ({
  className,
}: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      height="28"
      viewBox="0 0 24 28"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m12.272 2.962a2 2 0 0 1 3.455 0l10.518 18.03a2 2 0 0 1 -1.727 3.008h-21.036a2 2 0 0 1 -1.728-3.008z"
        transform="matrix(0 1 -1 0 24 0)"
      />
    </svg>
  )
}

export default FilledCaretRight
