import React from 'react'
import { ThemeProvider } from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import { TailwindConfig } from 'tailwindcss/tailwind-config'
import tailwindConfig from '../../tailwind.config'

// typescript-disable-next-line
const { theme } = resolveConfig(tailwindConfig as unknown as TailwindConfig)

// eslint-disable-next-line react/jsx-props-no-spreading
const Theme = (props: any): React.ReactElement => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ThemeProvider {...props} theme={theme} />
)

export default Theme
