import React from 'react'
import { navigate } from 'gatsby'
import cx from 'classnames'
import { useLocalization } from 'gatsby-theme-i18n'
import ReactMarkdown from 'react-markdown'
import { ButtonType, ImageType } from '../../types'
import FilledCaretRight from '../icons/components/FilledCaretRight.svg.react'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'
import { Button } from '../Button'
import { removeSlashes } from '../../lib'

type SectionTextImageProps = SectionProps & {
  title?: string | null
  titleTag?: keyof JSX.IntrinsicElements
  subtitle?: string | null
  flipped?: boolean
  actionLinks?: Array<{
    url: string
    label: string
    variant?: ButtonType
    external?: boolean
  }> | null
  actionLink?:
    | ((props: React.HTMLAttributes<HTMLElement>) => React.ReactElement)
    | null
  actionLabel?: string | null
  text?: string | React.ReactElement
  image?: ImageType | React.ReactElement | null
  framelessImage?: boolean
}

const defaultProps: Partial<SectionTextImageProps> = {
  title: undefined,
  titleTag: 'h2',
  subtitle: undefined,
  flipped: false,
  actionLinks: undefined,
  actionLink: undefined, // deprecated
  actionLabel: undefined, // depre
  image: undefined,
  text: undefined,
  framelessImage: false,
}

const SectionTextImage = (props: SectionTextImageProps): React.ReactElement => {
  const {
    flipped,
    title,
    titleTag,
    subtitle,
    actionLink,
    actionLabel,
    actionLinks,
    text,
    image,
    containerClass,
    framelessImage,
  } = props
  // const hasTitleSection = title || subtitle
  const hasTextBlock = title || text
  const hasActionLink = actionLink && actionLabel
  const ActionLink = actionLink as (
    props: React.HTMLAttributes<HTMLElement>
  ) => React.ReactElement
  const TitleTag = titleTag as keyof JSX.IntrinsicElements
  // const breakpoint = 'lg'
  const { locale } = useLocalization()

  return (
    <Section containerClass={containerClass}>
      <div className="relative overflow-hidden">
        <Container className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-10">
          {/* Text */}
          {hasTextBlock && (
            <div
              className={cx('max-w-xl mx-auto lg:py-16 lg:max-w-none', {
                'xl:py-24': framelessImage,
                'lg:col-start-2': flipped,
              })}
            >
              <div>
                <div
                  className={cx(
                    // mt-6
                    `bg-dirtyWhite bg-opacity-10 p-5 md:p-9 z-0 rounded-xl`
                  )}
                >
                  <div className="mb-7">
                    {title && <TitleTag className="text-5xl">{title}</TitleTag>}

                    {subtitle && <p className="text-2xl mt-1">{subtitle}</p>}
                  </div>

                  {text && (
                    <ReactMarkdown className="text-xl font-thin">
                      {text}
                    </ReactMarkdown>
                  )}

                  {actionLinks && !!actionLinks.length && (
                    <div className="mt-6">
                      {actionLinks.map(({ url, label, variant, external }) =>
                        external ? (
                          <Button as="a" href={url} title={label}>
                            {label}
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              navigate(`/${locale}/${removeSlashes(url)}`)
                            }
                            className=""
                            variant={variant || 'secondary'}
                          >
                            {label}
                          </Button>
                        )
                      )}
                    </div>
                  )}

                  {/* Deprecated */}
                  {hasActionLink && (
                    <div className="mt-6">
                      <ActionLink className="inline-flex items-center px-5 py-3 bg-gray-600 rounded text-white border-gray-600">
                        <span className="mr-3 fill-current text-white">
                          <FilledCaretRight className="w-5 h-5" />
                        </span>
                        {actionLabel}
                      </ActionLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Image */}
          {image && (
            <div
              className={cx('mt-12 sm:mt-16 lg:mt-0', {
                'flex items-center': !framelessImage,
                'lg:col-start-1': flipped,
                'col-span-2': !hasTextBlock,
              })}
            >
              {framelessImage ? (
                <div
                  className={cx('lg:px-0 lg:m-0 lg:relative lg:h-full', {
                    'pl-4 -mr-48 sm:pl-6 md:-mr-16': !flipped,
                    'pr-4 -ml-48 sm:pr-6 md:-ml-16': flipped,
                  })}
                >
                  {React.isValidElement(image) ? (
                    image
                  ) : (
                    <img
                      src={image.uri}
                      className={cx(
                        'w-full lg:absolute lg:h-full lg:w-auto lg:max-w-none',
                        {
                          'lg:left-0': !flipped,
                          'lg:right-0': flipped,
                        }
                      )}
                      alt={image.alt || ''}
                    />
                  )}
                </div>
              ) : (
                <>
                  {React.isValidElement(image) ? (
                    image
                  ) : (
                    <img
                      src={image.uri}
                      className={cx(
                        'w-full lg:absolute lg:h-full lg:w-auto lg:max-w-none',
                        {
                          'lg:left-0': !flipped,
                          'lg:right-0': flipped,
                        }
                      )}
                      alt={image.alt || ''}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Container>
      </div>
      {/* <div className="mt-24">
        <Container className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Better understand your customers
                </h2> 
                <p className="mt-4 text-lg text-gray-500">
                  Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis
                  bibendum malesuada faucibus lacinia porttitor. Pulvinar
                  laoreet sagittis viverra duis. In venenatis sem arcu pretium
                  pharetra at. Lectus viverra dui tellus ornare pharetra.
                </p>
                <div className="mt-6">
                  <a
                    href="#"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </Container>
      </div> */}
    </Section>
  )
}
SectionTextImage.defaultProps = defaultProps

const SectionImageText = (props: SectionTextImageProps): React.ReactElement => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SectionTextImage {...props} />
}
SectionImageText.defaultProps = { ...defaultProps, flipped: true }

const SectionImage = (
  props: SectionTextImageProps & {
    image: ImageType | React.ReactElement | null
  }
): React.ReactElement => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SectionTextImage {...props} />
}
SectionImage.defaultProps = { ...defaultProps }

export { SectionTextImage, SectionImageText, SectionImage }
