import React from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import cx from 'classnames'
import { useLocalization, LocalizedLink as Link } from 'gatsby-theme-i18n'

import { Container } from '../Layout'
import { PostPreview } from '../PostPreview'
import { Section, SectionProps } from './Section'
import { Button } from '../Button'

type SectionRecentPostsProps = SectionProps & {
  numberOfPosts?: number
  showMore?: boolean
  showSmaller?: boolean
}

const defaultProps: Partial<SectionRecentPostsProps> = {
  numberOfPosts: 4,
  showMore: true,
  showSmaller: false,
}

const SectionRecentPosts = (
  props: SectionRecentPostsProps
): React.ReactElement => {
  const { numberOfPosts, showMore, showSmaller } = props
  const { locale } = useLocalization()
  const {
    allWpPost: { edges },
  } = useStaticQuery(graphql`
    query RecentPostsQueryTop3 {
      allWpPost(limit: 10, sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            slug
            excerpt
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
          }
        }
      }
    }
  `)
  const { containerClass } = props
  const posts = edges.slice(0, numberOfPosts).map(({ node }: any) => node)
  return (
    <Section containerClass={containerClass} showSmaller={showSmaller}>
      <Container>
        {posts && !!posts.length && (
          <div
            className={
              showSmaller
                ? 'grid  sm:grid-cols-1 lg:grid-cols-3 lg:flex lg:justify-evenly gap-6 md:gap-20 lg:gap-6'
                : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'
            }
          >
            {posts.map(
              (
                { title, slug, categories, featuredImage, excerpt }: any,
                i: number
              ) => {
                const isHighlight = i === 3
                return (
                  <div
                    key={slug}
                    className={
                      showSmaller
                        ? cx('h-60 md:h-96 lg:w-72 lg:h-60', {
                            'sm:col-span-1 lg:col-span-3 lg:h-post-preview-highlight':
                              isHighlight,
                          })
                        : cx('h-80', {
                            'sm:col-span-1 lg:col-span-3 lg:h-post-preview-highlight':
                              isHighlight,
                          })
                    }
                  >
                    <Link to={`/blog/${slug}`} language={locale}>
                      <PostPreview
                        title={title}
                        subtitle={
                          <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                        }
                        labels={categories.nodes}
                        featuredImage={featuredImage.node}
                        isHighlight={isHighlight}
                      />
                    </Link>
                  </div>
                )
              }
            )}
          </div>
        )}

        {showMore && (
          <div className="pt-10 flex justify-center">
            <Button
              variant="secondary"
              onClick={() => navigate(`/${locale}/blog`)}
            >
              Alle Beiträge
            </Button>
          </div>
        )}
      </Container>
    </Section>
  )
}
SectionRecentPosts.defaultProps = defaultProps

export { SectionRecentPosts }
