import React, { SVGProps } from 'react'

const PayactiveIcon = ({
  className,
  style,
}: SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    enableBackground="new 0 0 1000 171"
    viewBox="0 0 1000 171"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <clipPath id="a">
      <path d="m171.64 70.94-9.22-15.76c-.87-1.48-3.01-1.49-3.88-.01l-5.34 9.02c-.42.7-.42 1.58-.01 2.28l3.16 5.41c1.9 3.25 1.9 7.27 0 10.52l-29.54 50.48c-1.87 3.19-5.29 5.15-8.99 5.15h-59.32c-3.7 0-7.12-1.96-8.99-5.15l-29.54-50.48c-1.9-3.25-1.9-7.27 0-10.52l29.54-50.48c1.87-3.19 5.29-5.15 8.99-5.15h59.33c3.7 0 7.12 1.96 8.99 5.15l3.38 5.77c.29.5 1.02.51 1.32.01l6.66-11.12c.42-.71.43-1.58.01-2.29l-2.91-4.98c-2.2-3.76-6.24-6.08-10.6-6.08h-73.03c-4.36 0-8.4 2.31-10.6 6.08l-36.37 62.15c-2.24 3.83-2.24 8.57 0 12.41l36.37 62.14c2.2 3.76 6.24 6.08 10.6 6.08h73.02c4.36 0 8.4-2.31 10.6-6.08l36.37-62.14c2.24-3.84 2.24-8.58 0-12.41z" />
    </clipPath>
    <clipPath id="b">
      <path d="m-2-2.28h180.31v158.84h-180.31z" />
    </clipPath>
    <clipPath id="c">
      <path d="m118.55 83.34 9.22 15.76c.87 1.48 3.01 1.49 3.88.01l5.34-9.02c.42-.7.42-1.58.01-2.28l-3.16-5.41c-1.9-3.25-1.9-7.27 0-10.52l29.54-50.48c1.87-3.19 5.29-5.15 8.99-5.15h59.33c3.7 0 7.12 1.96 8.99 5.15l29.54 50.48c1.9 3.25 1.9 7.27 0 10.52l-29.54 50.48c-1.87 3.19-5.29 5.15-8.99 5.15h-59.33c-3.7 0-7.12-1.96-8.99-5.15l-3.38-5.78c-.29-.5-1.02-.51-1.32-.01l-6.66 11.12c-.42.71-.43 1.58-.01 2.29l2.91 4.98c2.2 3.76 6.24 6.08 10.6 6.08h73.02c4.36 0 8.4-2.31 10.6-6.08l36.37-62.14c2.24-3.83 2.24-8.57 0-12.41l-36.37-62.13c-2.2-3.76-6.24-6.08-10.6-6.08h-73.02c-4.36 0-8.4 2.31-10.6 6.08l-36.37 62.14c-2.24 3.83-2.24 8.57 0 12.4z" />
    </clipPath>
    <clipPath id="d">
      <path d="m111.88-2.28h180.31v158.84h-180.31z" />
    </clipPath>
    <g fill="#10a4c7">
      <path d="m361.89 152.36c0 2.39-1.59 4.02-4 4.02h-6.74c-2.41 0-4-1.63-4-4.02v-79.99c0-22.28 15.22-34.46 32.7-34.46 17.46 0 32.54 12.19 32.54 34.46v17.63c0 23.7-14.91 34.63-31.57 34.63-7.39 0-14.45-3.06-18.93-5.78zm17.8-40.71c9.78 0 18.11-5.78 18.11-22.45v-16.03c0-15.24-8.17-22.28-17.94-22.28-10.11 0-17.96 7.04-17.96 22.28v32.87c5.77 3.35 11.7 5.61 17.79 5.61z" />
      <path d="m461.3 72.83h16.18v-4.48c0-11.22-5.44-17.46-15.87-17.46-6.72 0-13.63 1.42-18.11 2.72-2.55.63-4-.5-4.82-3.22l-.96-3.85c-.63-2.72.34-4.65 3.06-5.44 3.85-1.09 12.02-3.18 20.83-3.18 18.45 0 30.46 11.52 30.46 30.44v29.48c0 15.08-13.63 26.8-30.13 26.8-17.48 0-30.63-11.73-30.63-26.8.01-16.17 12.2-25.01 29.99-25.01zm.65 38.82c9.3 0 15.54-6.41 15.54-13.82v-13.44h-16.19c-10.57 0-14.91 5.95-14.91 13.32.01 7.2 5.79 13.94 15.56 13.94z" />
      <path d="m620.68 72.83h16.18v-4.48c0-11.22-5.44-17.46-15.87-17.46-6.72 0-13.63 1.42-18.11 2.72-2.55.63-4-.5-4.82-3.22l-.96-3.85c-.63-2.72.34-4.65 3.06-5.44 3.85-1.09 12.02-3.18 20.83-3.18 18.45 0 30.46 11.52 30.46 30.44v29.48c0 15.08-13.63 26.8-30.13 26.8-17.48 0-30.63-11.73-30.63-26.8.01-16.17 12.2-25.01 29.99-25.01zm.65 38.82c9.3 0 15.54-6.41 15.54-13.82v-13.44h-16.18c-10.57 0-14.91 5.95-14.91 13.32 0 7.2 5.77 13.94 15.55 13.94z" />
      <path d="m664.65 73.96c0-25 14.26-36.06 34.46-36.06 7.68 0 13.94 1.26 19.56 2.72 2.55.63 3.52 2.39 2.89 5.11l-.96 4.52c-.48 2.55-2.58 3.52-4.82 2.85-4.82-1.09-10.09-2.22-16.04-2.22-13.46 0-20.35 7.04-20.35 23.87v12.98c0 16.67 6.89 23.91 20.35 23.91 6.09 0 11.39-1.13 15.72-2.09 2.72-.63 4.65.17 5.13 2.55l1.11 4.82c.48 2.39-.48 4.31-3.2 4.98-5.28 1.42-11.54 2.72-19.39 2.72-20.21 0-34.46-11.22-34.46-36.22z" />
      <path d="m753.51 48.08h-7.22c-2.39 0-3.52-.84-4.17-3.14l-1.44-4.19c-.63-2.3.34-3.81 2.41-3.81h10.43v-20.26c0-2.51 1.28-4.31 3.85-4.98l5.76-1.34c3.2-.67 4.98 1.01 4.98 3.48v23.12h12.67c2.07 0 3.52 1.51 3.52 3.64v3.85c0 2.14-1.44 3.64-3.52 3.64h-12.67v70.31c0 2.47-1.61 4.15-4.02 4.15h-6.57c-2.39 0-4-1.68-4-4.15v-70.32z" />
      <path d="m808.64 12.87c0-5.15 4.17-9.34 9.3-9.34 4.98 0 9.3 4.19 9.3 9.34s-4.31 9.46-9.3 9.46c-5.13 0-9.3-4.14-9.3-9.46zm1.93 28.22c0-2.47 1.61-4.15 4.02-4.15h6.72c2.41 0 4.02 1.68 4.02 4.15v77.3c0 2.47-1.61 4.15-4.02 4.15h-6.72c-2.41 0-4.02-1.68-4.02-4.15z" />
      <path d="m849.33 44.78c-.96-2.89.63-4.77 3.04-4.77h6.74c2.41 0 4.17 1.09 4.96 3.69l14.59 45.18c1.59 4.98 3.2 12.02 4.79 18.59 1.44-6.57 3.22-13.61 4.82-18.59l14.59-45.18c.8-2.26 2.55-3.69 4.96-3.69h6.74c2.72 0 4 1.59 3.04 4.77l-24.37 73.45c-.8 2.55-2.87 4.31-5.28 4.31h-8.98c-2.41 0-4.48-1.59-5.3-4.19z" />
      <path d="m933.37 71.58c0-21.98 14.26-33.67 31.74-33.67 17.63 0 31.89 12.48 31.89 30.61v12.98c0 2.39-1.59 4.02-4 4.02h-44.89v4.82c0 14.74 7.22 21.31 19.24 21.31 7.37 0 13.63-1.47 18.11-2.6 2.55-.8 4.48.17 4.96 2.43l1.13 4.98c.65 2.72-.65 4.48-3.2 5.28-5.61 1.59-12.19 2.89-21 2.89-19.89 0-33.98-11.89-33.98-34.3zm48.89 2.38v-5.44c0-10.43-6.89-17.63-17.15-17.63-9.95 0-17 6.74-17 19.56v3.52h34.15z" />
      <path d="m574.03 40.23h-14.59v41.98 3.32 13.44c0 7.41-6.24 13.82-15.54 13.82-9.78 0-15.56-6.74-15.56-13.94l.13-58.69-15.21-.25v59.06c0 15.08 13.15 26.8 30.63 26.8 5.79 0 10.92-1.83 15.54-4.36v3.19 3.32 13.44c0 7.41-6.24 13.82-15.54 13.82l-8.39-1.36-4.79 12.42 13.18 1.92c16.5 0 30.13-11.73 30.13-26.8v-41.36h-.24c.02-.37.24-.67.24-1.04v-58.73z" />
    </g>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <image
          height="332"
          overflow="visible"
          transform="matrix(.48 0 0 .48 -2.4 -2.4)"
          width="377"
          xlinkHref="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAjfAAANFgAAGF//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAU0BewMBIgACEQEDEQH/
xADFAAEAAwEBAQEAAAAAAAAAAAAAAQUGBAMCBwEBAAIDAQAAAAAAAAAAAAAAAAEEAgMFBhAAAQMD
AwUBAQEBAQEAAAAAAgEDBAARBRAgEjBAExQVBhZQITEzEQACAAIECgcGBwEAAwAAAAABAhEDACEx
QSBAUWGBEiKiMwSRwTJC0hMjMHGh0eHiEFCxUmJyFIKSQ1MSAAECAgcGBAcBAQEAAAAAAAEAAhEx
EDAhQXESMiBRYZIDM4GRoSJAscHRQnIT4VLw/9oADAMBAAIRAxEAAAD9AAAAAAj5ye2vcVdUvcew
V7PVYK8WCvFgrxYK8WCvFgrxYK8WCvFgrxdXOMat/wCgKK9o9kMNoAAAAAAAA4pxpqU6vmwz1AAA
AAAAAAAANhj+vTa2qJ5noQAAAAAAAIx13lrvIC5ywAAAAAAAAAAABJsO/n6OR6cMdgAAAAAD4+87
s00/idTzYTAC1+NdV6VGvFXoUa8FGvBRrwUa8FGvBRrwUfzfJjFcm4xNzlfI3015U7ar0PQUO2AA
AAAABzYmyrOjwQsUgExe4bbnqOV6QIyAAAAAAAUV7GerANb93eT4W5R64Y7AAAAAAFbYYvfT5R0u
AAB7baruOd3Qr3gAAAAAAAAAAAAAAAAB4zFRnPv46nnA2aAHdw7LRb7ZOb6AAAAAAAAAAAAAAeaP
RXdGWHSMdgADL3WNt8wL3HAH3E2uo8fbl+iDXYAAAAAAAAAAAAA+MvYVNvmphvp6Puy2npdb6Gqw
iafLXScR1fNhlgA0dNtKnS+hR7IAAAAAAAAAAAADn6KPPVVwX+OAuqb6x2a55etDseeJvs3e44Wu
aALTHZdWRyvSBjmAAAAAAAAAAAAAprnzz15N9/F/jggCxv8AH3+m1TVNvUWOeGyuA2uK2dXpdoo9
kAAAAAAAAAAAAAACnp7epu8qEtuiAPfw6Mcu7M77E45c4tc0kRY1847N8q7Tl+jDHMAAAAAAAAAA
AAB4+1Dnq4PmV7kwkQENBxXmm0q7RWv4BY1/U85EpnGJSe+yw1/V6N6KXXAAAAAAAAAAAAEHLnen
nucyIls0QmB9/F3js7/QpdUInkxu9ztrnUkpucmJTGUfcTE7H3zWl5/dDXvAAAAAAAAAAAVvfm9t
fziVrnwmEQlL30nL10+kGveA8/QjE+ejzvR4RM56omUS1OX69VnVImj2QAAAAAAAAABzzFfW/UW+
ZCYywhJEd3HpNe/1FXogAARk9bxba2VmZu8eJTGSUxleWuS1NTp+g1WgAAAAAAAAFDY0u6rCY3VI
SmIifU77X5+qnTDHMAAADO12ty1vl/Ep2V0piVnW/WO3VufoqdUIkAAAAAAB8/VZOPD5Ss8+IlOM
RKUXdfe6bIabYAAAACot/nLDJT7eVvkpSlKccuq+zHZqtXita7FkrhYq6U2CvFgrxYK8WCvgsVdC
O2h9fLbXiJZ6YiRCe9Pf7lXoAkAAAAADhotXQbqfHMttVKYyfUTGUzExlMxMSmJiQSAEkTCESmIi
SIiUxAl9X/H36bYa9wAAAAAADw9yMusq6zzkpQmJjKSYlMSyTEwBIASRJECUBEJiYe3jd47PeTRc
AAAAAAAAAimumWrMzYcO6rAYzMTGSYlKYmAJACQIgCJiYT92kZ+fcabQRkAAAB//2gAIAQIAAQUA
2tMk4ox2hrxN14m68TdeJuvE3XibrxN14m68TdeJujitlTjZAXREVJWwQB6j7XMOjEa/51jVFPe0
2pmiIiaSJCgvsO17Dtew7XsO17Dtew7XsO17DtR3vIlSneI9CM1wDRw0AFVVXoNmoF7bdjNTLfHa
5nrJd5F2SIqq02gBpId4B1rXpWzRNYjV10VbU855D6zDdkp5viVACmQigppKdsnWaHkejgcxst4j
dh0MkETJSLrNlxK+pBZ1j/5aTL8OwZv49F/9iOXHQhQhMVEus2HItSO7oEokJIQ6SW7p1mg4jo4f
Ebreorll0WnQ4F1GQuurp8i0RbU25zG9Xp4OY9NEuoogpo6dh2MucCvV6vTwWXpNDreyGXJdrLnI
b1eiRCRUsvQFLqn/ADV0t4FxK90vo4F64FXAq4rXFa4rXEq4FXAqAbJoRWRVuu9o+u4V16CLZUK6
dQysnSFSSkW/TUrUSqq7f//aAAgBAwABBQDbJlAwLk6Qa+w/XsP17D9ew/XsP17D9ew/XsP17D9e
y/TWQfBWXgeDomYgLzpOudSHI8LvRyci69ZlFFrfIeRloiUl0gwhdH0Y1ejGr0Y1ejGr0Y1ejGr0
Y1ejGqbF8B1jo/M+hPkeV3RhlXnBFBHoPsi82mMf5NNC03vnyPE1rj4/jb7JVQUkvq87pCj+Z3rK
qIgvNkuuTkWTREVVisIy11pj3IqiveQKedFpszIz0xsfkXWkOKDejLitnyHjk3lJzRpsnDbbFsOs
835G1RUXQHFWPLv7GmL4+XsJXHzaDe2TYVD0bMmzacFwOs+74wXUGlSO62LgONk2dqtWPf4l1pDn
kPRlpXDslqyMfkNqtSf8qM95W+pJc4jrGa4BoqIqSGFactVqiu+JzpqqIjhKZaRmuR7JbHlbtSJS
JUR3kHSkuaoiqrYIA7ZbPA0SkSmyUCFUJOgZoIrdV0jN73W0cBRVFRKRKYd4p5m68oV5QryhXlCv
KFeUK8oU65zXQAUiRERN8pqrUiUlJ02A4j0FRFQwUC6jQci6TogSEHHpiCLQIKJt/9oACAEBAAEF
AOmqoiTP0cRlXf0mROvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvvZWvv
ZWvvZWvvZWvvZWvvZWkz2VRWP08oahZaHN7d11tlvK5d2cfboqouFzaul2v6LIq693KLasLP92J2
eVnJBiKqqvdYycUGWJCQ9iq2rMT/AHpfeYQyPF9j+gnrGj94IkRQYyRInYOugy1MlHLk7oP5+TKb
/l49fy8ev5ePX8vHr+Xj1/Lx6/l49fy8ev5ePX8vHr+Xj1/Lx6/l49fy8ev5ePRflmuM/GSYBbPz
mP5udj+kn8i3YOAkyV2M2I3MjOtGy7pDiuS5DDLcdnsMhMCFFMzcPaAE4eOhjCidl+ixquDpgMcs
Vjsc9P8AalbvzcBSPs1S9SPz+PfOLhMfFLsc5kPTi7ocVyXIYZbjs/4xkIDkZpzZW789A8Ef/H/S
T+AbsRAWdLRLf48uS3EjvvOSHtqIqriYKQonevPNMAWchosefFkrv/RT/O/u/OwPM/3rroMtypTk
p6kJRXFzVlM7ctPSDEVVVdrDDkh6JGbiR+9zczkesKSUWQJCY6qtqy89Z0vd+cx/BvvZ0oYsciIi
2YSZcdf0U/wR92NhFOlgIgPe55wlf2tOG05GfCQzT7zcdmXJOXI3YOB6cTvs8wXLdhpnier9NJUG
N2Bge1K799kH2nmjZd2oqov0l4/pXOWQ3YyMMWD/AIGdYTfyLw/pW1GfuxMsJcH/AAM5IQi3cC8P
6WKrkbdhZ/pyu/kyAjMuGTh7RFSL5qWcbB1ufDOFK3YKf7UXvstL8z27DREcc0z0D2ou6DLOHJbc
B1vvMjK9aPuZZN51loGGtc1A9OVttX56cqp3aqiJOkrJf3YaJ429mShDNikKiW1pw2nIcoJcfusv
K4N7oMVZUhERE2/oYHBzW1Wq1YOb67/cvOgy286Tzu7HRPWY3SGAkMyY5xn7VarVarVasVN9uN3G
Wlcz3YqJ5nuhn4PlZtVqtVqtVqx8tYklFQk7aZISOwqqS7QAnDjMBHZ6CoipkYSw5NqtVqtVqtWE
meRrtshJ9h/diItunlYSS41rVarVarVamHTYdZdB9rtMnJ8TO6NHKQ8ACAdPNQkYftVqtVqtVqw0
rxn2ZkIDIeJ93djIvhZ6kqOElhxs2nLVarVarUKqKwpKSWOyysjfj4vsP9bNw7parVarVarVj5Pr
v9jIeFhoyIy2oiksSOkdjrGAmEqMUZ+1Wq1Wq1IlRsg+wP2K+vX1q+tX1a+rX1a+rX1a+rX1a+rX
1a+rX1qLLFZ+Q6+W7FRuR9hlYvnYtVtiaJ2zLRPONti032OSi+u/3mMjcG+ymRkksKKivdRI/sPI
iInZ5WLxLuURVWFH9dntCESGbjzYXtxEiWFB8S9vL+byJARe0bRiofp8ep//2gAIAQICBj8A2bLA
Jlac37WrQzlC0M5QtDOULQzlC0M5QtDOULQzlC0M5QtDOULQzlCsGQ8PssrqoNEyg0XVtmoWiq/o
b7G17iJFxhUBvnggBIU5WTvK1LUtS1LUtS1LUjHU2jIJu+VTE6nW+FJcfDFEmZqQ4KMHR3IuN9RE
6W2nYyjS35/BwF6DfPGmzU6wfevsUS07H9DdY2mJRN0hhX5jM/KiIk6gNF6DRIU5BN08K8AypI8l
CFslnM3SwpLjci4zNeDsNdvjHGCZDdS2Eo2/T4ERpbj9EWGbbRhSWmRRabq+F1+w1u6McYIOFyDh
I05xNs8K/iZ0k+SjfOjIZGWOwRddWxMhsWSFMQgb78aeIlWQCApgJnZtk6exESPzq8x8KYlE7UDN
tJBUDUwUKco8dsFRFMQpKSkpKSkpKS40xUTUZT4V8N1TFRreNXYIqRFXYCVbtf/aAAgBAwIGPwDZ
ifc46W/+uWvINzLPWa7vV5yu71ecru9XnK7vV5yu71ecru9XnK7vV5yu71ecru9XnK7vU5yvcf6N
3On5oPYcReDxqi9xgGiJTnuvkNwuFaCdD/a77+FUOg0ytfjcK9jXamsaDiBUOeZyaN5RcTEuMSaf
6dUe2TWyjxXbHmV2x5ldseZXbHmV2x5ldseZXbHmV2x5lAtjkfKNx3Uf1cPb05cXf5U5Wn2dOwcT
eaWsF8zuF5Qa0QDRAVJY6+R3HeoEsy/9R+ib02yb68aiDT7+pYOAvOxncPf1LcG3fBlxMABElF5l
Jo3CkRHsZ7nfQV8TYFAPaTjsDotNrrX4XCkAWk2IN/I2uxr8gPtbPiaIE+5th+9Duo78R5nci9xi
XGJpPWcLGWN/bf4V7nCch40h3ngs0fbCMeCHSGllp/Y0tY2bjD/U1jZNEK8tvuxUDdT1GH8YEYEr
qx/7NL46svt+vwLoeONL/wBfqEOsJPsdiKWvbNpimvbJwrybzYMdjqPP5QhgCnMdJwTmOm0wp/k7
S/T+3+19mltgpDbpnBZYWQhDhQOs0Wtsdhv8KYhB35Cx2NblE3fLYt1OtP2pINoNiLbptwpt0usd
96wkyCLjfTmOlvz2bNTLR9RsZDqZ8qvIMTSAJlBo8cdrMNL7fG+kOFyBEjUkqJvpznAbZafDFEGY
spyulcp+hU/RT9FNTU/RT9FP0VkhSGhACQqP6DB1fmM3fKpIMii01vATq4OIabiptdgau1zWjFQb
aNr/2gAIAQEBBj8A9mSTACsk0KSAZ7i8HVTpvodTUlC7VWJ3o04+4nhpx9xPDTj7ieGnH3E8NOPu
J4acfcTw04+4nhpx9xPDTj7ieGnH3E8NOPuJ4acfcTw04+4nhpx9xPDTj7ieGnH3E8NOPuJ4acfc
Tw04+4nhoCZ0cxRPDQCfKSYMqxU9Ypqy31Zn/wA3qbRlxdps1giIIsxuoUQlOWB2UvaF7YwCDAis
EUXlecaMw1Spp738Wz4t/jlH05fEh3nyaP1xqItoNcxnytmZn/a2nFGmCHmtsygf3G/RQkmJNZJx
tZ1qHZmDKpt6KBlMVYRBF4OJRNlCyH0ZezLzi9tOO8uzGJAK6FYqPgMS/wA8swmzwQT+1L+mzHQq
iLMYAC8mkrl70Xa/sa2+JxF5sw6qICzHMKPzD2uahkUWDDE2a4kS2EUiNZj/AMxEBTjv0CnHfoFO
O/QKcd+gU479Apx36BTjv0CnHfoFOO/QKcd+gU479Apx36BTjv0CnHfoFOO/QKHU5hg10VBHVQCa
AZbGCTF7J+RwTzswbKRWVG9r20YkORlmpYNOOfur14evMEZMmDMMp7q4k8h+8Nlv2sLDR5UwaroS
rDOPxSRLtc1m3VF50USTKEEQQUYi89oEipFPeY2CjTHJZ2MWY2knCVEGs7EKoF5NQokgdrtTDlc2
4n/tkiLIITgP2jvaL/xPMTVhOnCwitUyabcS8pD6MglRnbvHDPPTBsrFZQymxm0YpA2ULwaUTWfL
IAOgg/CgdUMxxY0w60NFQ+GJFEPrToqmYd5sNJEu1zWbdUXnRRJMoQRBBR+Tl3IVVBLE2AC00ece
xZLXIosw/wDVMHqzxs5pd3Tb0flA5KWdt4NNhcty6cNVYeim1NOa4aaQFn5O/MTOygjC8m4UedNM
XcxY4QAESagBRUI9V9qaf5G7RjxeawRReaQAdhlAEPiRTVlPt/tNR+PsByssxlyDtZ5lh/8AGzpw
/wDXMHpSTsRvf7ceabMMFURJoZr1RqVblGT8Aykgiwio0g/Fl1NnyNhM4PqvsyhnN+ihJMSayThJ
JliLzDAUTl5fZQQjeTeceHKodla5kL2uGjAWaOzY4yqbaB1MVYAg5QcCJsozA+imzKGbLpwzzswb
TxWVG5b20480w1tYgysaFmMWYxJN5OCeVc1rXL914wBysswmzhtZpdh6bMNJIiE7Uxsij52UCIAq
qAFAsAFgx6XL7qprD3sSOrCWYhgyGINFnJYwrGQ3j8HnTTBEEWNJnMPUXMQMguGjDDOPWnQZ8w7q
4/L5gdmGo2a8Yf8Anc+nNOzmf6/hL5ZTDzTrOP4rZ8cPzXEZMiDHIW7o6/yB5L9lxA/OjSnEGQwP
zwoioiw01oCH+fzv+9bVh00Va4JLAhdEknDlS1tKhnOVmrP5DL5gWk6jfqOvDhGryoaPNj+tFe55
YOkEjDluO0gCTBkZR12/kKcupjq7T+89n4Yetd5Wto83V/WicwoiZJg39W+RwwHMJM3ZeJqBubR+
QNNa6wZTcKM7mLMYk4QVREsYAZzTViIeR5MP5R1tbpo0uYNZHBVgbwaPIasAxRsqmw4flOYzpAAM
b17px/yU4cqr3tfhnmXGzLqQZWy6Px85BGdIBIheveGGk9awKnXKptFFmIYo4DKcxx06p9R9lOs6
MNZSCLMYCiykEFUQGASghJm7SQFQN66MM8lMNkWlR3l6+nHCSYAVkmjP3BUgzfXDPMONuZUn9cun
BaTVrjalsbmFnTZQqwgymBBuIwlmSzB0MVOcUSene7QyMLRjY5dDtPW39frhqncFbnN9aAAQAqAG
EOcljZmVTMzXHTh+Q59KcQBme46caaa9SqImjTX7TGP0wxrD1HrfqGjDeTMEUcQPz0UeRM7SGEco
uOnDGsYzZcFmZ8jacZHLodlK3ztk0Yfmtw5Vfva72I5qWNuVU+dPphrM/wDWdmYP4n5UBBiDWCMX
Z+8akGc0JNZNZOEEQRZjACiylutOU3n2JBEQbRRpY4bbUs/xPywzyznbl1p/XJoxc6pjLSpOs4Z5
lxml9Z9mQo9WXFpeU5V00rwlmpUyGIos1DFXERivlKduZV7lvw1lCoHtHIBbQIogqiAGYe089B6c
4xIyNf024Z5dzszK0ORsmnFC7GCqIk+6jTGvsGQXDD8xh6kyv3LcPavJexhUchuNGluIMpgRnGEG
BgRWDRX74qcZ/riY5dTnfqGHFh6aVtkOQe3HNIKxBZgzXNhjWPpvU/UdGJNMNo7Iym4ULsYsxiT7
8IAVk1AUVO9a5yk+3KOIqwgRmNGlGsCtTlBswwlToLAbtNODvfbTg73204W99tOFvfbThb3204W9
9tOFvfbThb3204W99tOFvfbThb3204W99tOFvfbThb3204W99tNmUAc7R6hSMw2WKLBhnmGFS1Jn
OI+YojMlVjOt4x5Za2segZaLLWxRAYkWUenMiVzG8Y757dp+zmX64mZZ7VqHIRQgiBFRGNhT2BW/
upAWYp/oQbLVP78uNQFZNgpA9tq2+WKlWEVNRBoXlAtKtzr78YCqIk2AUE2bXM7q/t+uMGEQ95lw
I+NXRTYJIziHWcV9UvDIoH6k09CGtfHte1//2Q=="
        />
      </g>
    </g>
    <g clipPath="url(#c)">
      <g clipPath="url(#d)">
        <image
          height="332"
          overflow="visible"
          transform="matrix(.48 0 0 .48 111.84 -2.4)"
          width="376"
          xlinkHref="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAhKAAAMKwAAFgH/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAU0BewMBIgACEQEDEQH/
xADIAAEAAgMBAQAAAAAAAAAAAAAAAQUDBAYCBwEBAAMBAQAAAAAAAAAAAAAAAAEDBAIFEAABAwEI
AgICAwEAAAAAAAACAQMEABAgMEAREhMFFBUGFlAhMSIyMxEAAgACBQYLBQcCBwEAAAAAAQIRAwAh
MUFRIEBhEiIzcYGRwdEyohMjowQw4ULiNBBQobFikhRSgvBystJDY4NkEgABAgMHAgUEAwEAAAAA
AAABAAIRMUEQIDAhcRIyUZFAYYGhsdEichPB8VID/9oADAMBAAIRAxEAAAD6AAAAABHii7qsdPTX
ZNpqp52p1UTtNWTZayJ2mrJstYbLWJ2WsNlrIbM6osN+gmO+kaO9VqCOgAAAAAAABpzzX6Rq81MJ
iRCUSlMTEgJiYkAEgAAATe0OzxZeCnaAAAAAAABHO2FRfiTC3NIJRMSmJSETIExMSACQAAAALjd5
++p1+hxcAAAAAAx5KTqvS8w1+ZIEwTNj5uqdVbNiq0VyxFcsRXLEVyxFesBXrAV8WI5/Ff0NmaB3
WAsq31z10bFlo3AkAAAAQa3PbGtr82UT3SBPrxccWb+QyemCQAAAAAAFVa+J451kx34QkBuXPNXd
WnaFegAAABXb3M25vCGrz5AmETsdHqbmX0QrvAAAAAAAAA0KnpKK3LgFlADNhQ6Sa6xo3hHQAAwT
FbVo2+TI64CJnd0+kqvzjL6QAAAAAAAAADX2COabujowTBPIg99Bzm/xdcCnYAA56z57RhkaMQEo
yx1Y3Hn1i9UObAAAAAAAAAAAMfP9JW90VcF2QggQdDsc/f0bpHNrz6qeuK3Ab/HAlAm+q+jz7ZGb
cAAAAAAAAAAAAiRzuG8or8BDquYQibqkyRZ07x7z+hi5e0qNfmyhflkQTFnz3Z7Jh9gIkAAAAAAA
AAAAABQ32DqvmyL/AD5hExKBa3HKdHXopNLd0tOMO6wJ6rlOrz7soy7wAAAAAAAAAAAAAAKSssK7
R5sodVygTv19hz3np+s5bufAvyALKtc99gw5sHshEgAAAAAAAAAAAANbZ5nqnViGjzvSBM+ZMnTV
txXqVVrFerkGxr7/ABgmBvx1dZzz/aCJAAAAAAAAAAAAHkr+dy4dPlzPmeqpnzKZz6/R8W73oz+m
Bpc52FBpw1w04J6nQtcnpBRrAAAAAAAAAAAAAUdpyNuSJ8zdgmfMpmfPo3um1tnN6gc2gIkanrZd
cBz2AAAAAAAAAAAAANCeaethq8iZhPMzCJm4q+vr1ZBR6AAAAAAAAAAAAAAAAAAEcfb89f56YW45
RKZRuRNxbROX1wjsAAAAABhy8h3Tb73He7cfcsGfP6IJAAAAAAAYsvN9VVeM1eRKJAJ6yn6ajeFW
0AAAAAAYUVfO5MeryA6rs+p4O0q19QoVeq+UIvlCL5Qi+UIvlCL5Qi+UPktOQy4bsAd0gT7x9BzZ
a5zL64JAAAAAAc70XMWZqoaPMAAAAAAAAAAAAA2exr7LP6gV6AAAAAAAGhvp54SLap1eOE8gAAAA
AAAAAALHQ7GvRsjP6gAAAAAAAAHjletjvPwi3qr/ADvI64AAAAAAAAAe8vTcXYrMz+mEdgAAAf/a
AAgBAgABBQC6AKVI2KVtGto1tGto1tStqVtStqVtStqUoItKiouCiKqoiImIY6pgtBomMY6LfAdx
WuOKi7yreVbyreVbyreVbyreVCWqWEmqX2w2jYZbUwRXRbXBvMhqtrp7iwmytVNaVNFtRFVQFBGx
09o4aLoqLqlhjqlrAWr+kMtxYjZaLaY6LQCpEiIiWPnjgWqWEOqUwGg2GSCKqqrjAWi2qP8Adv8A
xZJ10yAa7bF/mOeo2EKEKpouMA6rapf3AlEkVFSiJBRV1XGAdqWGW1LI7lj7m5cZodVtMty27y0x
kTVUTRLHS0TJtD+rFXRCXVb4BuVWh0VNMEB3La6WCA7UpxtVXjOuM64zrjOuM64zrjOuM6ANqWEW
1P5wGv8AWM4Wq4CLooqipiOFomECklIWuGR6USqq3f/aAAgBAwABBQC668jaE+4Vch1yHXIdch1y
HW863nW863nXIdC+aUJISYJEgiRKRYjJ7SwZLmq4zJ7hvvObA11tYYQk4W64W64W64W64W64W64W
64W6db2LYBKJIqKl593edjQKZoiImA4G4VTS2Od6U7sG2O3sDCkB+7EVUUCQhtJUFHHFM7IzW88N
URUIVErGHNpWzHbURVVptGwxHw1G1lzeNOuI2CkqrZDa1XGdDYVjTmwtU0mOKR2NgpmIoKYzre8b
RcVWHv8ArZB03ZB5U5LA/wAzG9p2NmoGi6pjPubA1tFtUYdbRwCFRWmwUyRNExnneQ7GW956WTGd
bIjO0caW7tG1hvYFvE3rjGSCJmplZFa3Fk5juq2CKkTYIA35D3EITHEJFRUwHnUbBVVVshtaJfVU
RHnVcOo0lATymK8pivKYrymK8pivKYrymK8pmpD3KVjLauGiIiX5aqjONFa2BgGKELgKBYkVreeF
IbaNDb24bbKFTSAg3f/aAAgBAQABBQDDIhFH+3aCj7WWVeym17KbXspteym17KbXspteym17GbXs
ZlexmV7GZXsZlexmV7GZXsZlexmV7GZXsZlexmUnYzEVvtnEpiUxITLPPAy3LmOySy6KorAnc2W7
KXzvZlFUViSEkM5PspXjsZuDJ4HskRIIy5BSX851snlayPbyto51h4mHQMTHHfeFhpxw3XLzHUOm
Ppmq9M1Xp2q9O1Xp269O3Xp269Q3XqG69Q3XqG69Q3XqG69Q3XqG6LqB0kRnY53urk6ZDtpXK7e6
mLyO5GUwkhlUUVugRAUd8X2cWfKSMwqqq3WmydcYZFhrJdpG2le66Twu4s+Usl+91EXaOTcbFwHm
iZdvQJPOzh9vK4mr0OMsl8RQRynZxt7d6JIWO8ioqYLrgNNyHyfevdZF8djKqiKk2Osd691UncGD
3MvcV7q4vO/l5sbyGbzbhNOMui81fmSRixyIjK6IkZRI4xmMx2cbjdvdVK2OX+0l+RIvdNE1XMvs
i+04BNHd1VFhSUksXe1l+OxejMFIebbFsM12sbUb0CV4z9wyEBmSSlSL3UROFnNkKEMthY797qpX
K1b3UvRL3XRPKkZ3soqvsXo75R3m3BcCpL4R2HXDdcuoiqsCKkWPnuzi8D+ta3eml6LXev8A9r3T
ROR3Py44yWCQhLW624TZ+W1Xbqqz7qIqrHZFhn8B3bIg9rWt3lXh7cVSfejPjIY/Ad1IFyRre4l4
e9YVb/TS+J7Pz5SRIykpLrWtxpsnnfFZp9kH2XmjZduoqosR1Xo2e7Sb5UmtbvSRNEs7qHvC9DbJ
qLne6moxHvQ4xSpAALYWEiEk6KsWRc6iHzv51wxbCXKOVIvdPD8ePc7SH5Me1sCcOLGCMxne/m3+
ph+VIvdr15MuWdR15NZ6bKCJGccJw7oopFAiDEj3lRFR3q4LqsdfEjlnu7neRJvdDD3n+H7ed4ca
9GYOS+wyDDX4YiQR7Gas2Ve6GDxM/h/kE7jbvdZCWZKRERMhIksRWy+SxkKH2USblJMgIzEh85D1
1P3XVQvDi5AiQR7OaU2VTTrjLkOU3Mj5L5BO5Xr3Qwed/I/IJ/G3b0c/xpOR7OckKKqqq3WWjfdi
RgiR8hKkBFYfeN965E76XHH7PX2evs9fZ6+z19nr7PX2evs9fZ6+z19nr7PX2evs9H8mcVJMp+U5
e+PQdo5H5JJIns5AiFMlAANhkfkTZDOznSQVixcl28HzIv8AGb6WD5crKd716sP5lts3D6+GMKLl
Hmm32ux6l+EWXaadec6nqRhDli27exTo1M0bQsoykDXrPW8WJ//aAAgBAgIGPwC75KUdVIdlIdlI
KQUgpBSCkFIKQWWSgcKAUBi6YW41x49cCHe5AKampqampqanchgeZnbHCjcjeiZNuZSGHC5C5AVQ
FsBM4kVG2PS5vPpbEonFh1vAKApbtHr4TdV3xaSaIk18GD1TfxFrengRG1uv8LbVvxaWmqh0x/K4
B0QcECK2EmiJ6+F2H0s2iQ+ceJpc8rkNxhrjwCgLYdfCbjW2JUcDyWWShgwubR64MLIhSUlJSUlJ
SUl52x8RCgwYhRGLCpw8gSFIjUYeQJ9F9073/9oACAEDAgY/ALvUminDTJcnd1yd3XJ3dcnd1yd3
XJ3dcnd1yd3XJ3dcnd1n9wURhFxoi41xc5GeFsEmz1x4GbcAmshqo27n+gXFcVxXFcVxXFcVlIyt
BURW/lxbkPraG99FAUwSOygbdh9L20cn/FyJ5OzOHvFZ2giiBFwkyGaLjWWlsTxbn64hBqiDS3aZ
O+bn6xq7+BaAJlBvfXF3Cbfi5nMZGwuNJaokzOdv7DTJuPChzFoNJFRWwSZ82hoqg0SGWPCozFx7
f8w7Ep/5u+bX/wCoCGlfAuh1/u1/4j5C3iT/AJtDhRAiojj5cjkLj3VdA+gKLTX5RaZjKwNEygBQ
QxyaDIWgUGZULP2NH5fWze4fc6XkMfYJunpcgeRzNzdsbHrDHLjIIuNbd5k358J+sSby1tDRMoNF
MDLNxkF90HDRAjMHBLqyGqia2/sNcm/XAJMgi6khpZsfKh6Ln7Fc/Yrn7Fc/Yrn7Fc/Yrn7FcvYq
IyaJC0N76IAZAZYBhUiOmPE8n5+mCWmRRaaYsTxZmdemGA9zWOoSVyY4dWuBw4vexg/IRQH/ADgW
jpne/9oACAEBAQY/APZlmIVRaTUKQkqZhxNQpslU4B/ujTedleim87K9FN52V6Kbzsr0U3nZXopv
OyvRTedleim87K9FN52V6Kbzsr0U3nZXopvOyvRTedleim87K9FN52V6Kbzsr0U3nZXopvOyvRTe
dleikS8dBUdFPFQMMVqPPTw22r1NRzczJhgo/wAQptbKDqoOfOAymBFhFtO6mkCYOqf6vfm3dofC
l1DS15zoMpgRWDpoH+IVONOaaq7yZUugXnPBrHw3qbmPFmZZjAARJNwFGmHq2IMAM97tjty6uFbs
yHpkNbVvDC4cefLMW60YjCgdTFWEQcwaa9iizE4UaY5izGJyw01u7jXqwiePCm8bkFN43IKbxuQU
3jcgpvG5BTeNyCm8bkFN43IKbxuQU3jcgpvG5BTeNyCm8bkFN43IKbxuQU2ZpB0iNNWYKj1WFhyz
6dzpTnGYdwh2JZ2tLe7L79xsS+rG9vdmRT4rVOBoVYQIqI05QdTBlMQeCizBf1hgb/bFhvGqQace
KkTWTblLLStmMBRZSWKLcTjmYnqKmqfhxy+7Y+HMq4DcfbFhu1qQaMePLPqXG01SRwxzRpbdVhA0
aW1q345cGPiJU2JwPtO4Q7czraE9+WssdW1zgBQKogoEABcBmvfKNtOtpX3ZYf4bHGIoCKwawfZN
McwVREmjTWtY2YC4ZYZh4kyttAuGbQNlCo6jVodGHFln07HaWtNI9kPTIalrmQvNw4svvGHhyoE6
WuGcEDrrWnDhx5azFtUxFFmJYw5Dh7Bpp61iDFjZQsxizGJOJOUEURZjAAY0WUKyK2OLG05z3yjY
mW6G9+X3DHZfq6G9/sNVTGVLqXSbzln1TiyKyxpvOdNLaxhUcDjRpbiDKYHKiKiKBydsVONPvytR
DCZMqGIF5y1lLaxrOAvNFloIKogBwZ2PUKKxU/BccsFj4b7L8GPFkl3MFURJOFGmmMDUgNyizL75
xtzbBgt3LnhVhFSIEaDRpZstU4qbMvuXO3LsxK+7IHpUNbVzDouGWA27TafTo48+1lHiS6xpF4y1
mr8JrGIvFFmIYqwBHH9jTmsUVDE3CjTHMWYxJyoCsmwUCHrmtzp92f66jw5lYwBvGWfSuajXL4bx
9kv04sA124TUOfLPqHGxLqTS3u+4GlG21TgwsoVYQZTAjAjKWYvWQgjhFP8Ay77+2kwG4KB+0HKA
FZNQokpLFEOE3n7hSaLZoOsNKwr/ABy9b/5dW3/t1KTCfiCkftA5stJq/EKxgbx9wrKWsSgYn9TW
j8MvUr+k1/N16S/UAVDYb8158vuHOxNOzof3/cDTPjOyg/UaFmMSTEnScpZS9ZyAOOln/H3V3U5K
NKexhCOBuNGlOIMhgenKiKiLDSVMNrKCeG/P9kxlS9lNOLceWfVOKzFZcfxbm+0eqQbSVPpW48WX
Klt1lUR4c+7lD4s4Q4FvPNlrJWoGtjgotNFRBBVEANA+0qwiCIEaKNL+A1ocVOT3rjw5Vddha4Z8
0xzBUBJOgUac9UeqMFuGX3jiEybAnQtwySVEZsvaXE4rkLLQRZjACiyVu6xxa858PRyzg0381HPl
guIype0+BwXjy2nyljKetofCTzfb/JmiDsPDU2gG/jz55zWgQUYsbBRpjmLOSWOk5QVRFiYAaTRZ
Qra1zixy4GsG0UJMoKxvUlfwFVNaXLGuPiMWP42Z/wByh8KTVVYzXnmyz6txspVL0teeL7oIQwnT
NmXiMW4stJMvrOYRwF54qJJTqoID7nLMYKoiSbgKNNsQbMsYKLMv+VMHiTRsRuT3/dA9JLO1MrmQ
tC4ceWss7tdqYf0i7joABACwZj3k9wi2DEnAChCynK3EwHTSEloOKzLapoZo8+Z1UEYYm4cdHnTD
F3MT0cWVCgVh4szamcNy8WYlmMFURJNwFGmRPdLVKXBceP7Fmyzquhipos9L6mGDC0ZmPSSz4cqt
9L+7L/kTBGVJNUbGe7ktzIejlnbmCMyFy3DjyO5cwkziAY2BrjzZk0wEd62zKU3nHioSTEmsk5SS
pYi7kADhokhLFFZxY2nMXnzOqgjDE3Cjzphi7mJyRLmAT0FmsSH/AHV0+m8z5KfTeZ8lPpvM+Sn0
3mfJT6bzPkp9N5nyU+m8z5KfTeZ8lPpvM+Sn03mfJT6bzPkp9N5nyU+m8z5KfTeZ8lPpvM+Snh+n
VT+pi35Bad7PbWawXADADLPrZgraKyho+Jsyl+lB2EGu3+YxA5B+eepIEdU1uwuUWmiog1UUQUC4
DMg56roIHgqIz3vJghOnQY4hblzMhBGdL2peJxXjpA53ruIyZO0+k/CM1/kyx4M01w+F7+W3Ollo
NZ3ICjEmiyBW1rtixtzVpU0ayOIMKFh4kg9VxdobOBLlKXdrFFO+mwb1DDiTQOfNzrQ1YVxshpoe
6LLMvMkBkjwMQP208MllxYBTyAtmsfUPNh/SiL/qZ+ah/gat2v8A1/3Rr5va/wD/2Q=="
        />
      </g>
    </g>
  </svg>
)

export default PayactiveIcon
