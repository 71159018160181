import React, { useState } from 'react'
import { navigate } from 'gatsby'
// import cx from 'classnames'
import ReactMarkdown from 'react-markdown'
import { useLocalization } from 'gatsby-theme-i18n'
import { Section, SectionProps } from './Section'
import { Container } from '../Layout'
import { ButtonType } from '../../types'
import { Button } from '../Button'
import { removeSlashes } from '../../lib'
import check from '../../images/check.svg'
import checkGreen from '../../images/checkGreen.svg'
import { Headline } from '../Typography'

export type SectionInfoProps = SectionProps & {
  title?: string | null
  titleTag?: string | null
  containerClass?: string
  from?: string
  bullets?: Array<{
    title: string
    text?: string
  }> | null
  tabs?: Array<{
    title: string
    text: Array<{ text: string }> | null
  }> | null
  image?: React.ReactElement | null
  actionLinks: Array<{
    url: string
    label: string
    variant?: ButtonType
  }> | null
}

const defaultProps: Partial<SectionInfoProps> = {
  title: null,
  titleTag: 'h2',
  bullets: null,
  tabs: null,
  image: null,
  actionLinks: null,
  from: '',
  containerClass: '',
}

const SectionInfo = (props: SectionInfoProps): React.ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const {
    tabs,
    bullets,
    image,
    actionLinks,
    from,
    title,
    containerClass,
    titleTag,
  } = props
  const { locale } = useLocalization()

  const tabClick = (clickedIndex: number) => {
    if (clickedIndex !== currentIndex) {
      setCurrentIndex(clickedIndex)
    }
  }

  return (
    <Section containerClass={containerClass}>
      <Container>
        <Headline
          tagName={titleTag as keyof JSX.IntrinsicElements}
          fontSize="small"
          variant="underline"
          className={from !== 'home-api' ? 'mb-5' : 'mb-0'}
        >
          {title}
        </Headline>
        <div
          className={
            from === 'home-api'
              ? 'mt-2 grid grid-cols-1 lg:grid-cols-6 items-center relative z-20'
              : 'grid grid-cols-1 lg:grid-cols-6 gap-10'
          }
        >
          {from !== 'home-api' && <div className="lg:col-span-3">{image}</div>}

          <div className="lg:col-span-3">
            {bullets && (
              <div
                className={
                  from === 'home-api'
                    ? 'bg-dirtyWhite bg-opacity-10 rounded-t-3xl px-8 py-5 pb-20 mt-3  md:rounded-l-3xl lg:pr-12 lg:pb-5 lg:mt-0 '
                    : 'bg-dirtyWhite bg-opacity-10 px-8 py-5'
                }
              >
                <ul className={from === 'home-api' ? 'space-y-6' : 'space-y-8'}>
                  {bullets.map(({ title: bulletTitle, text }, index) => (
                    <li className="flex gap-4">
                      {from === 'home-api' ? (
                        <div>
                          <h1
                            className="h-8 w-8 bg-white rounded-full  text-center "
                            style={{ color: '#313e46', paddingTop: '3px' }}
                          >
                            {index + 1}
                          </h1>
                        </div>
                      ) : (
                        <img
                          src={check}
                          alt="Haken"
                          className="w-8 h-8 mt-1.5"
                        />
                      )}
                      <div>
                        <span className="font-normal block mb-1">
                          {bulletTitle}
                        </span>
                        {text && (
                          <ReactMarkdown className="font-light">
                            {text}
                          </ReactMarkdown>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {tabs && (
              <div>
                <ul className="flex flex-nowrap">
                  {tabs.map(({ title: tabTitle }, index) => (
                    <button
                      className="mr-3"
                      type="button"
                      id={`${
                        index !== currentIndex
                          ? 'list-not-selected'
                          : 'list-selected'
                      }`}
                      onClick={() => tabClick(index)}
                    >
                      <h1
                        style={{ color: '#33444C' }}
                        className="font-light text-base block m-1 cursor-pointer"
                      >
                        {tabTitle}
                      </h1>
                    </button>
                  ))}
                </ul>
                <ul>
                  <h1
                    className="font-normal text-xl block mb-2 mt-5"
                    style={{ color: '#33444C' }}
                  >
                    {tabs[currentIndex].title}
                  </h1>
                  {tabs[currentIndex].text?.map(({ text }) => (
                    <li
                      className="flex mb-5 items-center"
                      style={{ color: '#33444C' }}
                    >
                      <img
                        src={checkGreen}
                        alt="Haken"
                        className="w-7 h-7 mt-1.5 mr-3"
                      />
                      <ReactMarkdown className="font-light text-base block">
                        {text}
                      </ReactMarkdown>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {from === 'home-api' && (
            <div className="lg:col-span-3 -mt-14 lg:mt-0 lg:-ml-8">{image}</div>
          )}
        </div>

        <div className="flex flex-col sm:flex-row flex-wrap items-center md:justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4 mt-10">
          {actionLinks &&
            !!actionLinks.length &&
            actionLinks.map(({ url, label, variant }) => (
              <Button
                className="text-black"
                onClick={() => {
                  if (from === 'home-api') {
                    window.open(url, '_blank')
                  } else {
                    navigate(`/${locale}/${removeSlashes(url)}`)
                  }
                }}
                variant={variant || 'secondary'}
              >
                {label}
              </Button>
            ))}
        </div>
      </Container>
    </Section>
  )
}
SectionInfo.defaultProps = defaultProps

export { SectionInfo }
