import React from 'react'
import tw from 'twin.macro' // eslint-disable-line import/no-extraneous-dependencies
import styled, { css } from 'styled-components/macro'
import longArrowWhite from '../images/loesungen_assetts/long-arrow-white.png'
import longArrowBlack from '../images/loesungen_assetts/long-arrow-black.png'
import { ButtonType } from '../types'

type ButtonProps = {
  children: React.ReactNode
  variant?: ButtonType
  isSmall?: boolean
  disabled?: boolean
  icon?: React.ReactNode
  // theme?: object
}

type StyledButtonProps = React.HTMLProps<HTMLButtonElement> & ButtonProps

const defaultProps: Partial<StyledButtonProps> = {
  variant: 'secondary',
  isSmall: false,
  disabled: false,
  icon: longArrowWhite,
}

const StyledButton = styled.button<StyledButtonProps>(
  ({ variant, isSmall, disabled }) => [
    // The base button styles added with the tw macro
    tw`inline-flex items-center px-5 py-3 bg-gray-600 rounded`,

    variant === 'link' && tw`bg-transparent underline text-white`,

    // Use props to conditionally style your components
    variant === 'primary' && tw`text-white bg-brand shadow`,

    // Combine regular css with Tailwind classes within backticks
    variant === 'secondary' &&
      css`
        /* box-shadow: 0 0.1em 0 0 rgba(0, 0, 0, 0.25); */
        ${tw`text-white bg-gray-600 border-gray-600`}
      `,

    variant === 'tertiary' && tw`text-black bg-white shadow`,
    // Conditional props can be added
    isSmall ? tw`text-sm` : tw`text-lg`,

    disabled && tw`opacity-40`,

    // Your tailwind.config.js styles are added by <Theme> in pages/index.js
    // css`
    //   color: ${theme.colors.black};
    // `,
  ]
)

export const Button = (props: ButtonProps): React.ReactElement => {
  const { children, variant } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton {...props}>
      {variant !== 'link' && variant !== 'tertiary' && (
        <span className="mr-4 fill-current text-white">
          <img className="w-6" src={longArrowWhite} alt="arrow-white" />
        </span>
      )}
      {variant === 'tertiary' && (
        <span className="mr-4 fill-current text-black">
          {' '}
          <img className="w-6" src={longArrowBlack} alt="arrow-black" />
        </span>
      )}

      {children}
    </StyledButton>
  )
}
Button.defaultProps = defaultProps

export default Button
