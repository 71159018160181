// const colors = require('tailwindcss/colors')
const pluginLineClamp = require('@tailwindcss/line-clamp')

module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: 'class',
  theme: {
    colors: {
      transparent: 'transparent',
      brand: {
        DEFAULT: '#24c2d0',
        light: '#00e2d1',
      },
      uglyPurple: '#b83b88',
      white: '#ffffff',
      seafoamBlue: '#7fc9c5',
      black: '#000000',
      topaz: '#17aecf',
      dirtyWhite: '#f9f9f9',
      coolBlue: '#3cacc4',
      titlesBlue: '#2faaba',
      dustyBlue: '#5e8cb3',
      dirtyPurple: '#423645',
      lipstick: '#c2277d',
      gray: {
        charcoal: '#33444c',
        600: '#333333',
        700: '#292929',
      },
    },
    // backgroundColors: (theme) => ({
    //   ...theme('colors'),
    // }),
    // fontSize: {
    //   xs: '.75rem',
    //   sm: '.875rem',
    //   base: '1rem',
    //   lg: '1.125rem',
    //   xl: '1.25rem',
    //   '2dxl': '1.5rem',
    //   '5xl': '3rem',
    //   '6xl': '4rem',
    //   '7xl': '5rem',
    // },
    extend: {
      fontFamily: {
        sans: ['Roboto', 'Arial', 'sans-serif'],
        serif: ['Roboto Slab', 'Georgia', 'serif'],
      },
      height: {
        'post-preview-highlight': '30rem',
      },
      backgroundSize: {
        full: '100% 100%',
      },
      backgroundOpacity: {
        15: '0.15',
      },
      zIndex: {
        '-1': '-1',
      },
      width: {
        textImageMedia: '620px',
        '50vw': '50vw',
      },
    },
  },
  // variants: {
  //   extend: {
  //   borderOpacity: ['focus', 'hover'],
  //   },
  // },
  plugins: [pluginLineClamp],
}
