import cx from 'classnames'
import React from 'react'

type LeadProps = React.InputHTMLAttributes<HTMLElement> & {
  tagName?: keyof JSX.IntrinsicElements
}

const defaultProps: Partial<LeadProps> = {
  tagName: 'p',
}

export const Lead = (props: LeadProps): React.ReactElement => {
  const { children, className, tagName } = props
  const Tag = tagName as keyof JSX.IntrinsicElements

  return (
    <Tag className={cx('text-3xl font-normal', className || 'mb-3')}>
      {children}
    </Tag>
  )
}
Lead.defaultProps = defaultProps

export default Lead
